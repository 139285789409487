import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
  useContext,
} from 'react';
import classNames from 'classnames/bind';
import DocumentTitle from 'react-document-title';
import { RouteChildrenProps } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Title } from '@sputnik/ui';

import { ProfileHeader } from '../../components/ProfileHeader';
import { ProfileInfo } from '../../components/ProfileInfo';
import { ToastLoadError } from '../../components/ToastLoadError';
import { ProfileSkeleton, ParamsSkeleton } from '../../components/Skeleton';
import { Employee } from '../../types/Employee';
import { getProfileHeader, getProfileInfo } from '../../selectors/employee';
import { fetch } from '../../api/employee';

import { UserContext } from '../../context';

import style from './Home.module.css';

const cx = classNames.bind(style);

export const Home: React.FC<RouteChildrenProps<{ id: string }>> = (props) => {
  const intl = useIntl();
  const [employee, setEmployee] = useState<Employee | null>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const title = intl.formatMessage({ id: 'profile.title' });
  const user = useContext(UserContext);

  const handleFetch = useCallback(() => {
    fetch(user.id)
      .then((employee) => {
        setIsLoading(false);
        setEmployee(employee);
      })
      .catch((error) => {
        if (error.status === 'NOT_FOUND') {
          props.history.replace('/404');
          return;
        }
        setIsError(true);
      });
  }, [props.history, user.id]);

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  const profileHeader = useMemo(() => {
    if (employee) {
      return getProfileHeader(employee);
    }

    return undefined;
  }, [employee]);

  const profileInfo = useMemo(() => {
    if (employee) {
      return getProfileInfo(employee, intl.locale);
    }

    return undefined;
  }, [employee, intl.locale]);

  return (
    <>
      <DocumentTitle title={title} />

      {isError && <ToastLoadError onClick={handleFetch} />}

      <div className={cx('title')}>
        <Title type='h2' as='h1'>
          {title}
        </Title>
      </div>
      <div className={cx('content')}>
        <>
          {isLoading || isError ? (
            <ProfileSkeleton isLoading={isLoading} />
          ) : (
            <ProfileHeader
              {...(profileHeader as ReturnType<typeof getProfileHeader>)}
            />
          )}
          <div className={cx('info')}>
            {isLoading || isError ? (
              <ParamsSkeleton isLoading={isLoading} />
            ) : (
              <ProfileInfo
                {...(profileInfo as ReturnType<typeof getProfileInfo>)}
              />
            )}
          </div>
        </>
      </div>
    </>
  );
};
