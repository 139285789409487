import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames/bind';
import { useCookies } from 'react-cookie';

import { Header as HeaderUI, Notifications, Button } from '@sputnik/ui';
import { ReactComponent as Book } from '@sputnik/ui/icons/book-open-outline.svg';
import { ReactComponent as ArrowLeft } from '@sputnik/ui/icons/arrow-left.svg';
import { ReactComponent as Logout } from '@sputnik/ui/icons/logout-variant.svg';
import { ReactComponent as Lock } from '@sputnik/ui/icons/lock-reset.svg';

import { Notification } from 'types/Notification';
import { UserContext, LanguageContext } from 'context';
import {
  getNotifications,
  getNotificationsCount,
  setNotificationRead,
} from 'api/notifications';

import { SelfDiagnosis } from '../SelfDiagnosis';
import { PasswordChange } from '../PasswordChange';

import style from './Header.module.css';

const cx = classNames.bind(style);

export const Header: React.FC = () => {
  const [count, setCount] = useState<number>(0);
  const [isOpenChangePassword, setIsOpenChangePassword] = useState<boolean>(
    false
  );
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const location = useLocation();
  const user = useContext(UserContext);
  const language = useContext(LanguageContext);
  const [, , removeCookie] = useCookies();
  const name = user.first_name;
  const position = undefined;
  const image = user.photo_preview;

  const intl = useIntl();
  const changePasswordMsg = intl.formatMessage({
    id: 'components.header.header.change-password',
  });
  const logoutMsg = intl.formatMessage({
    id: 'components.header.header.logout',
  });

  const isOnline = matchPath(location.pathname, { path: '/online' });
  const isOnlineCourse = matchPath(location.pathname, {
    path: '/online/:courseId',
  });

  const handleOpen = () => {
    getNotifications().then((data) => {
      setNotifications(data);
    });
  };

  const handleClose = () => {
    notifications
      .filter((item) => !item.is_read)
      .map(({ id }) => setNotificationRead(id));

    setCount(0);
    handleOpen();
  };

  const right = (
    <Notifications
      count={count}
      notifications={notifications}
      onOpen={handleOpen}
      onClose={handleClose}
    />
  );

  useEffect(() => {
    getNotificationsCount().then((value) => {
      setCount(value);
    });
  }, []);

  const handleLogout = useCallback(() => {
    const win = window as any;

    if (win.onLogout) {
      win.onLogout();
    } else {
      removeCookie('token');
      window.location.pathname = '/';
    }
  }, [removeCookie]);

  const getContent = () => {
    if (isOnlineCourse) {
      return (
        <Button
          as='Link'
          to='/online'
          size='m'
          startIcon={ArrowLeft}
          color='text'
        >
          <FormattedMessage id={'pages.recovery.back'} />
        </Button>
      );
    }

    if (isOnline) {
      return (
        <Button
          as='Link'
          to='/profile/'
          size='m'
          startIcon={ArrowLeft}
          color='text'
        >
          <FormattedMessage id={'pages.notfound.to-personal-account'} />
        </Button>
      );
    }

    return (
      <>
        <SelfDiagnosis />
        <div className={cx('courses')}>
          <Button
            as='Link'
            to='/online/'
            size='l'
            startIcon={Book}
            color='white'
          >
            <FormattedMessage id={'pages.onlinecourses.online-courses'} />
          </Button>
        </div>
        <div className={cx('courses')}>
          <Button
            as='a'
            href='http://10.64.2.197:53270/ecplibjsf/pages/index.jsf'
            target='_blank'
            size='l'
            color='white'
          >
            <FormattedMessage id={'components.header.header.library'} />
          </Button>
        </div>
      </>
    );
  };
  return (
    <>
      <HeaderUI
        name={name}
        position={position}
        image={image}
        right={right}
        language={language.value}
        onChangeLanguage={language.onChange}
        profileMenu={[
          {
            icon: Lock,
            title: changePasswordMsg,
            onClick: () => setIsOpenChangePassword(true),
          },
          {
            icon: Logout,
            title: logoutMsg,
            onClick: handleLogout,
          },
        ]}
      >
        <Button
          as='a'
          href='https://sdot.ttc.kz/news.html'
          target='_blank'
          size='l'
          color='white'
        >
          <FormattedMessage id={'components.header.header.news'} />
        </Button>
        <div className={cx('menu-laptop')}>{getContent()}</div>
        <PasswordChange
          isOpen={isOpenChangePassword}
          onRequestClose={() => setIsOpenChangePassword(false)}
        />
      </HeaderUI>
      <div className={cx('menu-mobile')}>{getContent()}</div>
    </>
  );
};
