import React from 'react';
import classNames from 'classnames/bind';

import { Profile } from '../Profile';

import logo from './assets/logo.svg';
import { Button } from '@sputnik/ui';

import style from './Header.module.css';

const cx = classNames.bind(style);

type Props = {
  name: string;
  position?: string;
  image?: string;
  right?: React.ReactNode;
  language?: string;
  onChangeLanguage?: () => void;
  profileMenu?: React.ComponentProps<typeof Profile>['menu'];
};

export const Header: React.FC<Props> = ({
  name,
  position,
  image,
  children,
  right,
  language,
  onChangeLanguage,
  profileMenu,
}) => {
  return (
    <header className={cx('header')}>
      <div className={cx('header__top')}>
        <img src={logo} className={cx('logo')} alt='' width={44} height={44} />
        <div className={cx('header__btn-block')}>
          <div className={cx('content')}>{children}</div>
        </div>
        <div className={cx('right')}>
          {right}
          <Profile
            name={name}
            position={position}
            image={image}
            menu={profileMenu}
          />

          {language && (
            <button
              type='button'
              onClick={onChangeLanguage}
              className={cx('language')}
            >
              {language}
            </button>
          )}
        </div>
      </div>
    </header>
  );
};
