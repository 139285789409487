import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Load } from '../../icons/load.svg';

import classNames from 'classnames/bind';

import style from './Button.module.css';

const cx = classNames.bind(style);

type ButtonProps = {
  as: 'button';
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

type AnchorProps = {
  as: 'a';
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

type LinkProps = {
  as: 'Link';
  to: string;
};

type Props = {
  size: 'xl' | 'l' | 'm';
  block?: boolean;
  startIcon?: React.ComponentType<any>;
  color?: 'white' | 'text';
  isLoading?: boolean;
} & (ButtonProps | AnchorProps | LinkProps);

export const Button: React.FC<Props> = ({
  size,
  as,
  children,
  block,
  startIcon: StartIcon,
  color,
  isLoading,
  ...rest
}) => {
  const Component = ((as === 'Link'
    ? Link
    : as) as unknown) as React.ComponentType<React.HTMLAttributes<HTMLElement>>;

  return (
    <Component
      className={cx('button', {
        button_block: block,
        button_loading: isLoading,
        button_size_xl: size === 'xl',
        button_size_l: size === 'l',
        button_size_m: size === 'm',
        button_color_white: color === 'white',
        button_color_text: color === 'text',
      })}
      {...rest}
    >
      <Load width={24} height={24} className={cx('load')} />
      {StartIcon && <StartIcon width={20} height={20} className={cx('icon')} />}
      {children}
    </Component>
  );
};
