import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import DocumentTitle from 'react-document-title';
import { RouteChildrenProps, Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { Title, Text, Description, Section } from '@sputnik/ui';
import { ReactComponent as Book } from '@sputnik/ui/icons/book-open-page-variant-outline.svg';
import { ReactComponent as Calendar } from '@sputnik/ui/icons/calendar-blank.svg';

import { getCourses } from '../../api/courses';

import style from './OnlineCourses.module.css';
import { Course } from 'types/Course';

const cx = classNames.bind(style);



export const OnlineCourses: React.FC<RouteChildrenProps<{ id: string }>> = (
  props
) => {
  const [courses, setCourses] = useState<(Course & { lessons: number })[]>([]);
  const handleFetch = async () => {
    const data = await getCourses();
    setCourses(data);
  };
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'pages.onlinecourses.online-courses' });  //'Онлайн курсы';
  
  useEffect(() => {
    handleFetch();
  }, []);

  return (
    <>
      <DocumentTitle title={title} />

      <div className={cx('content')}>
        <div className={cx('title')}>
          <Title type='h2' as='h1'>
            {title}
          </Title>
        </div>
        <div className={cx('description')}>
          <Text color={600}>
            <FormattedMessage id={'pages.onlinecourses.you-can-choise-course-for-schedule'} />
          </Text>
        </div>
        <div className={cx('courses')}>
          {courses.map((item) => (
            <Section level={0} key={item.id}>
              <Link to={`/online/${item.id}`} className={cx('course')}>
                <Title type='h4' as='p'>
                  {item.name}
                </Title>
                <span className={cx('courseInfo')}>
                  <span className={cx('courseParam')}>
                    <Book width={20} height={20} />
                    <Description as='span' color={900}>
                      {item.lessons} <FormattedMessage id={'pages.onlinecourses.lessons'} />
                    </Description>
                  </span>
                  <span className={cx('courseParam')}>
                    <Calendar width={20} height={20} />
                    <Description as='span' color={900}>
                      {item.duration_days || 0} <FormattedMessage id={'pages.onlinecourses.days'} />
                    </Description>
                  </span>
                </span>
              </Link>
            </Section>
          ))}
        </div>
      </div>
    </>
  );
};
