import React from 'react';
import classNames from 'classnames/bind';

import style from './Page.module.css';

const cx = classNames.bind(style);

type Props = {
  header: React.ComponentType<any>;
};

export const Page: React.FC<Props> = ({ header: Header, children }) => {
  return (
    <main className={cx('page')}>
      <Header />
      <div className={cx('content')}>{children}</div>
    </main>
  );
};
