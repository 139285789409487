import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useIntl } from 'react-intl';

import * as yup from 'yup';
import { Formik, Form } from 'formik';

import { Modal } from '@sputnik/ui';

import { changePassword } from '../../api/login';

import { FormValues } from './types';
import { FormCurrent } from './FormCurrent';
import { FormNew } from './FormNew';

import style from './PasswordChange.module.css';

const cx = classNames.bind(style);


type State = 'CURRENT' | 'NEW';

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
};

const password = yup
  .string()
  .min(8, 'MIN_LENGTH')
  .matches(/[A-ZА-ЯЁ]/, 'UPPERCASE')
  .matches(/[a-zа-яё]/, 'LOWERCASE')
  .matches(/(\d|[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/, 'SPECIAL');

export const PasswordChange: React.FC<Props> = ({ isOpen, onRequestClose }) => {
  const [state, setState] = useState<State>('CURRENT');
  const intl = useIntl();
  const passwordMsg = intl.formatMessage({ id: 'components.password-change.wrong-password' });
  const unknownErrMsg = intl.formatMessage({ id: 'pages.recovery.unknown-error' });
  
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} withClose>
      <Formik<FormValues>
        validationSchema={yup.object().shape({
          password: yup.string().required(),
          passwordNew: password,
          passwordNewConfirm: password.oneOf([yup.ref('passwordNew')]),
        })}
        initialValues={{
          password: '',
          passwordNew: '',
          passwordNewConfirm: '',
        }}
        onSubmit={async (values, helpers) => {
          try {
            await changePassword(values.password, values.passwordNew);
            onRequestClose();
          } catch (errors) {
            if (errors.code === 'invalid_password') {
              helpers.setErrors({
                password: passwordMsg,
              });
              setState('CURRENT');
              return;
            }

            helpers.setErrors({
              passwordNew: unknownErrMsg,
            });
          }
        }}
        validateOnMount
      >
        {({ values }) => {
          let validation = [];

          try {
            password.validateSync(values.passwordNew, {
              abortEarly: false,
            });
          } catch (error) {
            validation = error.errors;
          }

          return (
            <Form className={cx('form')}>
              {state === 'CURRENT' && (
                <FormCurrent onSubmit={() => setState('NEW')} />
              )}

              {state === 'NEW' && (
                <FormNew
                  validation={validation}
                  onBack={() => setState('CURRENT')}
                />
              )}
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
