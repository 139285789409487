import React from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames/bind';

import { ButtonIcon } from '../ButtonIcon';
import { ReactComponent as Close } from '../../icons/close.svg';
import { ReactComponent as ArrowLeft } from '../../icons/arrow-left.svg';

import style from './Modal.module.css';

const cx = classNames.bind(style);

type Props = {
  isOpen: boolean;
  withClose?: boolean;
  onRequestClose?: () => void;
};

export const Modal: React.FC<Props> = ({
  isOpen,
  children,
  onRequestClose,
  withClose,
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      overlayClassName={cx('overlay')}
      className={cx('modal')}
      shouldCloseOnOverlayClick
      onRequestClose={onRequestClose}
      closeTimeoutMS={300}
    >
      {withClose && (
        <div className={cx('close', 'close_mobile')}>
          <ButtonIcon
            onClick={onRequestClose}
            icon={ArrowLeft}
            size='xl'
            as='button'
          />
        </div>
      )}
      {children}
      {withClose && (
        <div className={cx('close')}>
          <ButtonIcon
            onClick={onRequestClose}
            icon={Close}
            size='xl'
            as='button'
            color='white'
          />
        </div>
      )}
    </ReactModal>
  );
};
