import { Employee } from '../types/Employee';
import { formatDuration } from '../utils/formatDuration';

const formatDate = (date: string) =>
  date.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3.$2.$1');

function notEmpty<T>(x: {
  label: string;
  value: string | undefined;
}): x is { label: string; value: string } {
  return !!x.value;
}

export const getProfileHeader = (item: Employee) => {
  return {
    image: item.photo_preview,
    fullName: [item.last_name, item.first_name, item.middle_name]
      .filter(Boolean)
      .join(' '),
    position: item.position.name,
    // phone: '!+7 700 070 0103'
    email: item.email,
  };
};

const getOrganization = (item: Employee) => {
  return [
    {
      label: 'profile.info.organization.department',
      value: item.business_unit?.name,
    },
  ].filter(notEmpty);
};

const getCareer = (item: Employee, locale: string) => [
  [
    {
      label: 'profile.info.career.position',
      value: item.position.name,
    },
    // {
    //   label: 'profile.info.career.category',
    //   value: '!A – II',
    // },
  ].filter(notEmpty),
  [
    // {
    //   label: 'profile.info.career.experience.total',
    //   value: '!8 лет',
    // },
    {
      label: 'profile.info.career.experience.company',
      value: formatDuration(item.employment_duration, locale),
    },
    // {
    //   label: 'profile.info.career.experience.position',
    //   value: '!3 года',
    // },
  ],
];

const getEducation = (item: Employee) =>
  [
    [
      {
        label: 'profile.info.career.education.type',
        value: item.education,
      },
      // {
      //   label: 'profile.info.career.education.specialty',
      //   value: '!Механик',
      // },
      // {
      //   label: 'profile.info.career.education.qualification',
      //   value: '!A – II',
      // },
      // {
      //   label: 'profile.info.career.education.degree',
      //   value: '!Доцент, магистр',
      // },
    ].filter(notEmpty),
    [
      // {
      //   label: 'profile.info.career.education.language',
      //   value: '!Английский, русский',
      // },
    ],
  ].filter((item) => item.length > 0);

export const getProfileInfo = (item: Employee, locale: string) => {
  return {
    header: [
      {
        label: 'profile.info.common.birthdate',
        value: item.birthdate ? formatDate(item.birthdate) : '',
      },
      // {
      //   label: 'profile.info.common.nationality',
      //   value: '!Русский',
      // },
      {
        label: 'profile.info.common.number',
        value: item.employee_number,
      },
    ].filter(notEmpty),
    organization: getOrganization(item),
    career: {
      params: getCareer(item, locale),
      // chief: {
      //   photo: 'https://picsum.photos/96',
      //   fullName: 'Аркадий Иванов',
      // },
    },
    education: getEducation(item),
  };
};
