import React from 'react';
import classNames from 'classnames/bind';
import ReactSelect, { ValueContainerProps, ControlProps } from 'react-select';

import { ReactComponent as MenuDown } from '@sputnik/ui/icons/menu-down.svg';
import { ReactComponent as MenuUp } from '@sputnik/ui/icons/menu-up.svg';

import style from './Dropdown.module.css';

const cx = classNames.bind(style);

export type Option<T> = {
  icon?: React.ReactNode;
  label: string;
  value: T;
};

type Props<T> = {
  options: Option<T>[];
  value?: T;
  placeholder?: string;
  disabled?: boolean;
  isSearchable?: boolean;
  onChange?: (value: T) => void;
};

const ValueContainer: React.FC<ValueContainerProps<any>> = ({
  children,
  hasValue,
  selectProps,
}) => {
  return (
    <div className={cx('valueContainer')}>
      <div
        className={cx('label', {
          label_visible: hasValue || selectProps.inputValue,
        })}
      >
        {selectProps.placeholder}
      </div>
      {children}
    </div>
  );
};

const Control: React.FC<ControlProps<any>> = ({
  children,
  innerProps,
  innerRef,
  isDisabled,
  isFocused,
  menuIsOpen,
}) => (
  <div
    className={cx('control', {
      control_disabled: isDisabled,
      control_focused: isFocused,
      control_open: menuIsOpen,
    })}
    ref={innerRef}
    {...innerProps}
  >
    {children}
  </div>
);

export function Dropdown<T>({
  options,
  placeholder,
  value,
  onChange,
  disabled,
  isSearchable = false,
}: Props<T>) {
  const selectedItem = options.find((item) => item.value === value) || null;

  return (
    <ReactSelect<Option<T>>
      isSearchable={isSearchable}
      isDisabled={disabled}
      value={selectedItem}
      options={options}
      placeholder={placeholder}
      noOptionsMessage={() => 'Не найдено'}
      onChange={(option) => {
        if (!onChange) return;

        if (!Array.isArray(option)) {
          onChange(option ? option.value : option);
        }
      }}
      components={{
        Control,
        Placeholder: ({ children, innerProps }) => (
          <div className={cx('placeholder')} {...innerProps}>
            {children}
          </div>
        ),
        ValueContainer,
        SingleValue: ({ children, innerProps }) => (
          <div className={cx('value')} {...innerProps}>
            {children}
          </div>
        ),
        IndicatorSeparator: () => null,
        DropdownIndicator: ({ innerProps, isFocused, selectProps }) => {
          const Icon = selectProps.menuIsOpen ? MenuUp : MenuDown;

          return (
            <Icon
              {...innerProps}
              width={20}
              height={20}
              className={cx('icon', {
                icon_focused: isFocused,
              })}
            />
          );
        },
        NoOptionsMessage: ({ innerProps, children }) => (
          <div className={cx('notFound')} {...innerProps}>
            {children}
          </div>
        ),
        Menu: ({ children, innerProps }) => (
          <div className={cx('menu')} {...innerProps}>
            {children}
          </div>
        ),
        Option: ({
          children,
          innerProps,
          innerRef,
          isSelected,
          isFocused,
          isDisabled,
          data,
        }) => (
          <div
            className={cx('option', {
              option_selected: isSelected,
              option_focused: isFocused,
              option_disabled: isDisabled,
            })}
            {...innerProps}
            ref={innerRef}
          >
            {data.icon && <div className={cx('optionIcon')}>{data.icon}</div>}
            <div className={cx('optionLabel')}>{children}</div>
          </div>
        ),
      }}
    />
  );
}
