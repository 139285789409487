import React from 'react';
import classNames from 'classnames/bind';
import { useIntl, FormattedMessage } from 'react-intl';
import { Description, Text } from '@sputnik/ui';

import { CompetenceRequest } from 'components/CompetenceRequest';

import { SkillType } from 'types/SkillType';

import style from './SkillTable.module.css';

const cx = classNames.bind(style);

type Props = {
  data: {
    id: number;
    title: string;
    type: SkillType;
    level: number;
    required: number;
    max?: number;
  }[];
};

const Level: React.FC<{
  type?: 'negative' | 'positive';
  max?: number;
  value: number;
}> = ({ value, type, max }) => (
  <p
    className={cx('level', {
      level_negative: type === 'negative',
      level_positive: type === 'positive',
    })}
  >
    {max ? `${value}` : `${Math.round(value)}%`}
  </p>
);

export const SkillTable: React.FC<Props> = ({ data }) => {
  const intl = useIntl();
  const label = {
    skill: intl.formatMessage({ id: 'competence.skill' }),
    knowledge: intl.formatMessage({ id: 'competence.knowledge' }),
  };

  const lowLabel = {
    skill: intl.formatMessage({ id: 'competence.skill.low' }),
    knowledge: intl.formatMessage({ id: 'competence.knowledge.low' }),
  };

  console.log(data);

  return (
    <table className={cx('table')}>
      <thead className={cx('thead')}>
        <tr className={cx('tr', 'tr_head')}>
          <th className={cx('th')}>
            <Description color={600} as='span'>
              <FormattedMessage id='competence.skills.table.name' />
            </Description>
          </th>
          <th className={cx('th')}>
            <Description color={600} as='span'>
              <FormattedMessage id='competence.skills.table.current' />
            </Description>
          </th>
          <th className={cx('th')}>
            <Description color={600} as='span'>
              <FormattedMessage id='competence.skills.table.required' />
            </Description>
            <Description color={600} as='span'>
              <FormattedMessage id='competence.skills.table.level' />
            </Description>
          </th>
          <th className={cx('th')} />
        </tr>
      </thead>
      <tbody className={cx('tbody')}>
        {data.map(({ id, title, level, required, type, max }) => (
          <tr className={cx('tr')}>
            <td className={cx('td')}>
              <Text size='s' color={900}>
                {title}
              </Text>
              <div className={cx('label')}>
                <Description color={level >= required ? 600 : 'negative-500'}>
                  {(level >= required ? label : lowLabel)[type]}
                </Description>
              </div>
            </td>
            <td className={cx('td')}>
              <Level
                type={level >= required ? 'positive' : 'negative'}
                max={max}
                value={level}
              />
            </td>
            <td className={cx('td')}>
              <Level max={max} value={required} />
            </td>
            <td className={cx('td')}>
              <CompetenceRequest competence={id} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
