import React from 'react';
import classNames from 'classnames/bind';
import DocumentTitle from 'react-document-title';
import { FormattedMessage } from 'react-intl';
import { Title, Button } from '@sputnik/ui';

import style from './NotFound.module.css';

const cx = classNames.bind(style);

const title = '404';

export const NotFound: React.FC = () => {
  return (
    <div className={cx('container')}>
      <DocumentTitle title={title} />
      <picture className={cx('image')}>
        <source
          srcSet={require('./assets/man-shrugging.webp')}
          type='image/webp'
        />
        <img
          src={require('./assets/man-shrugging.png')}
          alt='404'
          width={80}
          height={80}
        />
      </picture>
      <Title type='h1'>{title}</Title>
      <p className={cx('text')}><FormattedMessage id={'pages.notfound.page-not-found'} /> :(</p>
      <div className={cx('button')}>
        <Button as='a' href='/' size='xl' block>
          <FormattedMessage id={'pages.notfound.to-personal-account'} />
        </Button>
      </div>
    </div>
  );
};
