import React from 'react';
import classNames from 'classnames/bind';

import { ReactComponent as MenuDown } from '@sputnik/ui/icons/menu-down.svg';

import style from './Select.module.css';

const cx = classNames.bind(style);

type Props = {
  options: {
    label: string;
    value: string;
  }[];
  value: string;
  onChange: (value: string) => void;
  iconPosition?: 'left' | 'right';
};

export const Select: React.FC<Props> = ({
  options,
  value,
  onChange,
  iconPosition = 'left',
}) => {
  const selectedLabel =
    options.find((item) => item.value === value)?.label || value;

  return (
    <label
      className={cx('label', {
        label_icon_right: iconPosition === 'right',
      })}
    >
      <MenuDown width={16} height={16} className={cx('icon')} />
      {selectedLabel}
      <select
        className={cx('select')}
        value={value}
        onChange={(event) => onChange(event.target.value)}
      >
        {options.map(({ label, value }) => (
          <option value={value}>{label}</option>
        ))}
      </select>
    </label>
  );
};
