import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ReactComponent as Alert } from '@sputnik/ui/icons/alert-circle-outline.svg';
import { Toast } from '@sputnik/ui';



type Props = {
  onClick?: () => void;
};

export const ToastLoadError: React.FC<Props> = ({ onClick }) => {
  const intl = useIntl();
  const renewMsg = intl.formatMessage({ id: 'components.toast-load-error.renew' }); 
  
  return (
    <Toast icon={Alert} button={renewMsg} onClick={onClick}>
      <FormattedMessage id={'components.toast-load-error.error-test-connection'} />
    </Toast>
  );
};
