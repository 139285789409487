import React from 'react';
import classNames from 'classnames/bind';

import { Text } from '@sputnik/ui';
import { ReactComponent as Positive } from '@sputnik/ui/icons/positive.svg';
import { ReactComponent as Negative } from '@sputnik/ui/icons/negative.svg';

import { Duration } from '../Duration';
import { CompetenceTreeSkill } from 'types/CompetenceTree';

import style from './CompetenceInfo.module.css';

const cx = classNames.bind(style);

type Props = {
  data: {
    title: React.ReactNode;
    endDate?: Date;
    items: CompetenceTreeSkill[];
  }[];
};

export const CompetenceInfo: React.FC<Props> = ({ data }) => {
  return (
    <div className={cx('info')}>
      <table className={cx('table')}>
        <tbody>
          {data.map(({ title, endDate, items }) => {
            const isExpired = endDate && endDate.valueOf() < Date.now();
            const isEmpty = items.some((item) => item.level !== 1);

            return (
              <tr className={cx('tr')}>
                <td className={cx('td')}>
                  <Text size='s' color={900}>
                    {title}
                  </Text>
                </td>
                <td className={cx('td')}>
                  {endDate && <Duration date={endDate} />}
                </td>
                <td className={cx('td')}>
                  {isExpired || isEmpty ? (
                    <Negative width={24} height={24} className={cx('icon')} />
                  ) : (
                    <Positive width={24} height={24} className={cx('icon')} />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
