import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { NavLink } from 'react-router-dom';

import logo from './assets/Logo.svg';

import { ReactComponent as Menu } from '../../icons/menu.svg';
import { ReactComponent as ArrowLeft } from '../../icons/arrow-left.svg';

import style from './Sidebar.module.css';

const cx = classNames.bind(style);

type Props = {
  items: {
    icon: React.ComponentType<React.SVGAttributes<SVGElement>>;
    label: React.ReactNode;
    href: string;
  }[];
  menuItems?: {
    icon: React.ComponentType<React.SVGAttributes<SVGElement>>;
    label: React.ReactNode;
    href?: string;
    onClick?: () => void;
  }[];
};

export const Sidebar: React.FC<Props> = ({ items, menuItems }) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  return (
    <aside className={cx('sidebar')}>
      <header>
        <img
          src={logo}
          className={cx('logo')}
          alt='Қазақстан темiр жолы'
          width={170}
          height={35}
        />
      </header>

      <nav className={cx('nav')}>
        <ul className={cx('list')}>
          {items.map(({ icon: Icon, label, href }) => (
            <li className={cx('listItem')}>
              <NavLink
                to={href}
                className={cx('link')}
                activeClassName={cx('link_active')}
              >
                <Icon width={24} height={24} className={cx('icon')} />
                {label}
              </NavLink>
            </li>
          ))}
          {menuItems && (
            <li className={cx('listItem', 'listItem_mobile')}>
              <button
                type='button'
                className={cx('link', {
                  link_active: isMenuOpen,
                })}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                <Menu width={24} height={24} className={cx('icon')} />
                Меню
              </button>
            </li>
          )}
        </ul>
      </nav>

      {menuItems && isMenuOpen && (
        <div className={cx('menu', 'menu_open')}>
          {menuItems.map(({ icon: Icon, label, onClick, href }) => {
            if (href) {
              return (
                <NavLink
                  className={cx('menuItem')}
                  onClick={() => {
                    onClick && onClick();
                    setIsMenuOpen(false);
                  }}
                  to={href}
                >
                  <Icon width={24} height={24} className={cx('menuIcon')} />
                  {label}
                </NavLink>
              );
            }
            return (
              <button
                type='button'
                className={cx('menuItem')}
                onClick={() => {
                  onClick && onClick();
                  setIsMenuOpen(false);
                }}
              >
                <Icon width={24} height={24} className={cx('menuIcon')} />
                {label}
              </button>
            );
          })}
          <button
            type='button'
            className={cx('menuItem')}
            onClick={() => setIsMenuOpen(false)}
          >
            <ArrowLeft width={24} height={24} className={cx('menuIcon')} />
            Назад
          </button>
        </div>
      )}
    </aside>
  );
};
