import React, { useState } from 'react';
import classNames from 'classnames/bind';
import useClickOutside from 'click-outside-hook';
import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';
import { useIntl, FormattedMessage } from 'react-intl';

import { ReactComponent as Bell } from '../../icons/bell.svg';
import { ReactComponent as Close } from '../../icons/close.svg';
import { Section } from '../Section';
import { Title } from '../Title';
import { ButtonIcon } from '../ButtonIcon';
import { Text } from '../Text';
import { Description } from '../Description';

import style from './Notifications.module.css';

const cx = classNames.bind(style);

type Notification = {
  message: string;
  timestamp: string;
  is_read: boolean;
};

type Props = {
  notifications: Notification[];
  count: number;
  onOpen: () => void;
  onClose: () => void;
};

const Notification: React.FC<{
  text: React.ReactNode;
  timestamp: string;
  unread?: boolean;
}> = ({ text, unread, timestamp }) => {
  return (
    <section className={cx('notification', { notification_unread: unread })}>
      <div className={cx('notificationIcon')}>
        <Bell width={18} height={18} />
      </div>
      <div>
        <div className={cx('notificationText')}>
          <Text color={900} size='s'>
            <span dangerouslySetInnerHTML={{ __html: text }} />
          </Text>
        </div>
        <Description color={500}>
          {format(new Date(timestamp), 'dd MMM hh:mm', { locale: ru })}
        </Description>
      </div>
    </section>
  );
};

const NotificationsGroup: React.FC<{ title: React.ReactNode }> = ({
  title,
  children,
}) => {
  return (
    <div className={cx('group')}>
      <div className={cx('groupTitle')}>
        <Description color={500} size='l'>
          {title}
        </Description>
      </div>
      {children}
    </div>
  );
};

export const Notifications: React.FC<Props> = ({
  notifications,
  count,
  onOpen,
  onClose,
}) => {
  const intl = useIntl();
  const [isActive, setIsActive] = useState(false);
  const handleOpen = () => {
    setIsActive(true);
    onOpen();
  };
  const handleClose = () => {
    setIsActive(false);
    onClose();
  };

  const ref = useClickOutside(handleClose);

  const unread = notifications.filter((item) => !item.is_read);
  const read = notifications.filter((item) => item.is_read);

  return (
    <div ref={ref}>
      <button
        className={cx('button', { button_active: isActive })}
        type='button'
        onClick={isActive ? handleClose : handleOpen}
      >
        <div className={cx('buttonContent')}>
          <Bell width={24} height={24} />
          {!!count && <span className={cx('buttonCount')}>{count}</span>}
        </div>
      </button>
      <div className={cx('container', { container_visible: isActive })}>
        <Section level={3}>
          <div className={cx('inner')}>
            <div className={cx('title')}>
              <Title type='h3' as='p'>
                <FormattedMessage id='notifications.title' />
              </Title>
              <div className={cx('close')}>
                <ButtonIcon
                  as='button'
                  icon={Close}
                  size='xl'
                  onClick={handleClose}
                />
              </div>
            </div>
            {!!unread.length && (
              <NotificationsGroup
                title={intl.formatMessage({ id: 'notifications.unread' })}
              >
                {unread.map(({ message, timestamp }) => (
                  <Notification timestamp={timestamp} text={message} unread />
                ))}
              </NotificationsGroup>
            )}
            {!!read.length && (
              <NotificationsGroup
                title={intl.formatMessage({ id: 'notifications.read' })}
              >
                {read.map(({ message, timestamp }) => (
                  <Notification timestamp={timestamp} text={message} />
                ))}
              </NotificationsGroup>
            )}
          </div>
        </Section>
      </div>
    </div>
  );
};
