import React from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames/bind';
import intervalToDuration from 'date-fns/intervalToDuration';

import { Description } from '@sputnik/ui';
import { ReactComponent as Timetable } from '@sputnik/ui/icons/timetable.svg';

import style from './Duration.module.css';

const cx = classNames.bind(style);


type Props = {
  date: Date;
  label?: React.ReactNode;
};

export const Duration: React.FC<Props> = ({ date, label }) => {
  const start = new Date();
  const duration = intervalToDuration({
    start,
    end: date,
  });
  const warning = date.valueOf() - start.valueOf() < 30 * 86400 * 1000;
  const intl = useIntl();
  const dayMsg = intl.formatMessage({ id: 'components.duration.day-short' });
  const monthMsg = intl.formatMessage({ id: 'components.duration.month-short' });
  
  return (
    <div
      className={cx('duration', {
        duration_center: !label,
        duration_warning: warning,
        duration_expired: date < start,
      })}
    >
      <Timetable width={24} height={24} className={cx('icon')} />
      <div className={cx('info')}>
        {label && (
          <div className={cx('label')}>
            <Description size='s' color={500}>
              {label}
            </Description>
          </div>
        )}
        <Description size='s' color={900}>
          {date < start
            ? `0 ${dayMsg}`
            : [`${duration.months} ${monthMsg}`, `${duration.days} ${dayMsg}`].join(' ')}
        </Description>
      </div>
    </div>
  );
};
