import React from 'react';

import classNames from 'classnames/bind';
import { Formik, Form, FormikProps } from 'formik';

import { Title, Text, Input, Button } from '@sputnik/ui';

import style from './LoginForm.module.css';

const cx = classNames.bind(style);

type Values = {
  login: string;
  password: string;
};

type Props = {
  title: React.ReactNode;
  description?: React.ReactNode;
  onSubmit: (login: string, password: string) => Promise<void>;
  validationSchema: any;
};

export const LoginForm: React.FC<Props> = ({
  title,
  description,
  children,
  onSubmit,
  validationSchema,
}) => {
  return (
    <Formik<Values>
      validationSchema={validationSchema}
      initialValues={{
        login: '',
        password: '',
      }}
      onSubmit={async (values, helpers) => {
        try {
          await onSubmit(values.login, values.password);
        } catch (errors) {
          helpers.setErrors(errors);
        }
      }}
      isInitialValid={false}
    >
      {({
        values,
        isValid,
        setFieldValue,
        isSubmitting,
        errors,
        submitCount,
      }) => {
        return (
          <Form className={cx('form')} noValidate>
            <Title type='h3' as='h1'>
              {title}
            </Title>
            {description && (
              <div className={cx('text')}>
                <Text color={600}>{description}</Text>
              </div>
            )}
            <div className={cx('field')}>
              <Input
                label='Логин'
                placeholder='Логин'
                value={values.login}
                onChange={(value) => setFieldValue('login', value)}
                name='login'
                type='email'
                // autoComplete='username'
                autoComplete='off'
                error={submitCount > 0 && errors.login}
              />
            </div>
            <div className={cx('field')}>
              <Input
                label='Пароль'
                placeholder='Пароль'
                value={values.password}
                onChange={(value) => setFieldValue('password', value)}
                name='password'
                type='password'
                // autoComplete='current-password'
                autoComplete='off'
                error={submitCount > 0 && errors.password}
              />
            </div>

            <div className={cx('submit')}>
              <Button
                as='button'
                type='submit'
                size='xl'
                block
                disabled={!isValid}
                isLoading={isSubmitting}
              >
                Войти
              </Button>

              {children}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
