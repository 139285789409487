import React from 'react';

import classNames from 'classnames/bind';

import style from './Tooltip.module.css';

const cx = classNames.bind(style);

type Props = {
  content: string;
};

export const Tooltip: React.FC<Props> = ({ content, children }) => {
  return (
    <div className={cx('tooltip')} aria-label={content}>
      {children}
    </div>
  );
};
