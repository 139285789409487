import React from 'react';
import classNames from 'classnames/bind';

import { SkeletonItem } from '../Item';

import style from './Tabs.module.css';

const cx = classNames.bind(style);

type Props = {
  isLoading: boolean;
};

export const TabsSkeleton: React.FC<Props> = ({ isLoading }) => (
  <div className={cx('wrapper')}>
    {[1, 2, 3, 4, 5].map((item) => (
      <div className={cx('item')} key={item}>
        <SkeletonItem isLoading={isLoading} theme={300} />
      </div>
    ))}
  </div>
);
