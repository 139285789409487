import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import classNames from 'classnames/bind';
import { useFormikContext } from 'formik';

import { Title, Text, Input, Button } from '@sputnik/ui';
import { ReactComponent as Check } from '@sputnik/ui/icons/check.svg';
import { ReactComponent as ArrowLeft } from '@sputnik/ui/icons/arrow-left.svg';

import { FormValues } from '../types';

import style from '../PasswordChange.module.css';

const cx = classNames.bind(style);

type Props = {
  validation: string[];
  onBack: () => void;
};

export const FormNew: React.FC<Props> = ({ validation, onBack }) => {
  const {
    values,
    isValid,
    isSubmitting,
    setFieldValue,
    submitCount,
    errors,
  } = useFormikContext<FormValues>();
  const intl = useIntl();
  const newPasswordMsg = intl.formatMessage({ id: 'components.password-change.new-password' });
  const repeatePasswordMsg = intl.formatMessage({ id: 'components.password-change.repeat-password' });

  return (
    <>
      <Title type='h3' as='h1'>
        <FormattedMessage id={'components.header.header.change-password'} />
      </Title>
      <div className={cx('field')}>
        <Input
          label={newPasswordMsg}
          placeholder={newPasswordMsg}
          value={values.passwordNew}
          onChange={(value) => setFieldValue('passwordNew', value)}
          name='passwordNew'
          type='password'
          autoComplete='new-password'
          error={submitCount > 0 && errors.passwordNew}
        />
      </div>
      <div className={cx('field')}>
        <Input
          label={repeatePasswordMsg}
          placeholder={repeatePasswordMsg}
          value={values.passwordNewConfirm}
          onChange={(value) => setFieldValue('passwordNewConfirm', value)}
          name='passwordNewConfirm'
          type='password'
          autoComplete='new-password'
        />
      </div>

      <ul className={cx('security')}>
        <li
          className={cx('check', {
            check_valid: !validation.includes('MIN_LENGTH'),
          })}
        >
          <div className={cx('checkIcon')}>
            <Check width={14} height={14} />
          </div>
          <Text size='s'><FormattedMessage id={'components.password-change.min-8-chars'} /></Text>
        </li>
        <li
          className={cx('check', {
            check_valid: !validation.includes('UPPERCASE'),
          })}
        >
          <div className={cx('checkIcon')}>
            <Check width={14} height={14} />
          </div>
          <Text size='s'><FormattedMessage id={'components.password-change.one-capital-letter'} /></Text>
        </li>
        <li
          className={cx('check', {
            check_valid: !validation.includes('LOWERCASE'),
          })}
        >
          <div className={cx('checkIcon')}>
            <Check width={14} height={14} />
          </div>
          <Text size='s'><FormattedMessage id={'components.password-change.one-lowercase-letter'} /></Text>
        </li>
        <li
          className={cx('check', {
            check_valid: !validation.includes('SPECIAL'),
          })}
        >
          <Text size='s'>
            <div className={cx('checkIcon')}>
              <Check width={14} height={14} />
            </div>
            <FormattedMessage id={'components.password-change.one-digit-and-spec'} /> <br /> (@, #, $, &, *, % 
            <FormattedMessage id={'components.password-change.etc'} />).
          </Text>
        </li>
      </ul>

      <div className={cx('submit')}>
        <Button
          as='button'
          type='submit'
          size='xl'
          block
          disabled={!isValid}
          isLoading={isSubmitting}
        >
          <FormattedMessage id={'components.header.header.change-password'} />
        </Button>
      </div>

      <div className={cx('submit')}>
        <Button
          color='text'
          as='button'
          type='button'
          onClick={onBack}
          size='m'
          startIcon={ArrowLeft}
        >
          <FormattedMessage id={'pages.recovery.back'} />
        </Button>
      </div>
    </>
  );
};
