import React from 'react';

import classNames from 'classnames/bind';

import { Text, Description } from '@sputnik/ui';

import style from './Param.module.css';

const cx = classNames.bind(style);

type Props = {
  label: string;
  value: string;
};

export const Param: React.FC<Props> = ({ label, value }) => {
  return (
    <li className={cx('param')}>
      <p className={cx('label')}>
        <Description as='span' color={600}>
          {label}
        </Description>
      </p>
      <Text color={900}>{value}</Text>
    </li>
  );
};
