import React from 'react';
import classNames from 'classnames/bind';

import { Tabs } from '@sputnik/ui';

import style from './Filters.module.css';

const cx = classNames.bind(style);

type Props = {
  tabs: {
    id: string;
    label: string;
    count: number;
  }[];
  onTabChange?: (value: string) => void;
};

export const Filters: React.FC<Props> = ({ tabs, children, onTabChange }) => {
  return (
    <div className={cx('filters')}>
      <div className={cx('tabs')}>
        <Tabs size='s' tabs={tabs} onChange={onTabChange} />
      </div>
      <div className={cx('selects')}>{children}</div>
    </div>
  );
};
