import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames/bind';

import { ReactComponent as Close } from '@sputnik/ui/icons/close.svg';

import style from './Toast.module.css';

const cx = classNames.bind(style);

type Props = {
  icon: React.ComponentType<React.SVGAttributes<SVGElement>>;
  button?: string;
  onClick?: () => void;
  onClose?: () => void;
};

export const Toast: React.FC<Props> = ({
  icon: Icon,
  children,
  button,
  onClick,
  onClose,
}) => {
  const container = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isClosed, setIsClosed] = useState(false);

  const handleClose = () => {
    setIsClosed(true);

    if (onClose) {
      setTimeout(() => {
        onClose();
      }, 300);
    }
  };

  useEffect(() => {
    if (container.current) {
      container.current.focus();
    }

    setIsVisible(true);
  }, []);

  return (
    <div
      className={cx('toast', {
        toast_visible: isVisible,
        toast_closed: isClosed,
      })}
      role='alert'
      tabIndex={0}
      ref={container}
    >
      <Icon className={cx('icon')} width={20} height={20} />
      <p className={cx('children')}>{children}</p>
      {button && (
        <button className={cx('button')} type='button' onClick={onClick}>
          {button}
        </button>
      )}
      <button className={cx('button')} type='button' onClick={handleClose}>
        <Close width={16} height={16} />
      </button>
    </div>
  );
};
