import React from 'react';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';

import classNames from 'classnames/bind';
import { Formik, Form } from 'formik';

import { Title, Text, Input, Button } from '@sputnik/ui';
import { ReactComponent as Check } from '@sputnik/ui/icons/check.svg';

import style from './PasswordNewForm.module.css';

const cx = classNames.bind(style);


type Values = {
  password: string;
  passwordConfirm: string;
};

type Props = {
  onSubmit: (new_password: string) => Promise<void>;
};

const password = yup
  .string()
  .min(8, 'MIN_LENGTH')
  .matches(/[A-ZА-ЯЁ]/, 'UPPERCASE')
  .matches(/[a-zа-яё]/, 'LOWERCASE')
  .matches(/(\d|[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/, 'SPECIAL');

export const PasswordNewForm: React.FC<Props> = ({ onSubmit }) => {
  const intl = useIntl();
  const newPasswordMsg = intl.formatMessage({ id: 'components.password-change.new-password' });
  const repeatePasswordMsg = intl.formatMessage({ id: 'components.password-change.repeat-password' });
  
  return (
    <Formik<Values>
      validationSchema={yup.object().shape({
        password: password.oneOf([yup.ref('passwordConfirm')]),
        passwordConfirm: password.oneOf([yup.ref('password')]),
      })}
      initialValues={{
        password: '',
        passwordConfirm: '',
      }}
      onSubmit={async (values, helpers) => {
        try {
          await onSubmit(values.password);
        } catch (errors) {
          helpers.setErrors(errors);
        }
      }}
      isInitialValid={false}
    >
      {({
        values,
        isValid,
        setFieldValue,
        isSubmitting,
        errors,
        submitCount,
      }) => {
        let validation = [];

        try {
          password.validateSync(values.password, {
            abortEarly: false,
          });
        } catch (error) {
          validation = error.errors;
        }

        return (
          <Form className={cx('form')} noValidate>
            <Title type='h3' as='h1'>
              <FormattedMessage id={'components.header.header.change-password'} />
            </Title>
            <div className={cx('field')}>
              <Input
                label={newPasswordMsg}
                placeholder={newPasswordMsg}
                value={values.password}
                onChange={(value) => setFieldValue('password', value)}
                name='password'
                type='password'
                autoComplete='username'
                error={submitCount > 0 && errors.password}
              />
            </div>
            <div className={cx('field')}>
              <Input
                label={repeatePasswordMsg}
                placeholder={repeatePasswordMsg}
                value={values.passwordConfirm}
                onChange={(value) => setFieldValue('passwordConfirm', value)}
                name='passwordConfirm'
                type='password'
                autoComplete='current-password'
                error={submitCount > 0 && errors.passwordConfirm}
              />
            </div>

            <ul className={cx('security')}>
              <li
                className={cx('check', {
                  check_valid: !validation.includes('MIN_LENGTH'),
                })}
              >
                <div className={cx('checkIcon')}>
                  <Check width={14} height={14} />
                </div>
                <Text size='s'><FormattedMessage id={'components.password-change.min-8-chars'} /></Text>
              </li>
              <li
                className={cx('check', {
                  check_valid: !validation.includes('UPPERCASE'),
                })}
              >
                <div className={cx('checkIcon')}>
                  <Check width={14} height={14} />
                </div>
                <Text size='s'><FormattedMessage id={'components.password-change.one-capital-letter'} /></Text>
              </li>
              <li
                className={cx('check', {
                  check_valid: !validation.includes('LOWERCASE'),
                })}
              >
                <div className={cx('checkIcon')}>
                  <Check width={14} height={14} />
                </div>
                <Text size='s'><FormattedMessage id={'components.password-change.one-lowercase-letter'} /></Text>
              </li>
              <li
                className={cx('check', {
                  check_valid: !validation.includes('SPECIAL'),
                })}
              >
                <Text size='s'>
                  <div className={cx('checkIcon')}>
                    <Check width={14} height={14} />
                  </div>
                  <FormattedMessage id={'components.password-change.one-digit-and-spec'} /> <br /> (@, #, $, &, *, %
                  <FormattedMessage id={'components.password-change.etc'} />).
                </Text>
              </li>
            </ul>

            <div className={cx('submit')}>
              <Button
                as='button'
                type='submit'
                size='xl'
                block
                disabled={!isValid}
                isLoading={isSubmitting}
              >
                <FormattedMessage id={'components.header.header.change-password'} />
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
