import { Course } from 'types/Course';

import { get } from './utils/get';
import { Lesson } from 'types/Lesson';

export async function getCourses() {
  const data = await get<Course[]>('/v1/courses');

  return Promise.all(
    data.map(async (course) => {
      const data = await get<Course[]>(`/v1/courses/${course.id}/lessons`);

      return {
        ...course,
        lessons: data.length,
      };
    })
  );
}

export async function getCourse(courseId: string) {
  const data = await get<Course>(`/v1/courses/${courseId}`);
  const lessons = await get<Lesson[]>(`/v1/courses/${courseId}/lessons`);

  return {
    ...data,
    lessons,
  };
}

export async function enrollCourse(courseId: string) {
  return get(`/v1/courses/${courseId}/enroll`, {
    method: 'POST',
  });
}
