import React, { useEffect, useState, useCallback, useContext } from 'react';
import classNames from 'classnames/bind';
import DocumentTitle from 'react-document-title';
import { RouteChildrenProps } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Title } from '@sputnik/ui';

import { fetchEmployeeCompetences } from '../../api/employee';

import { TabsSkeleton } from '../../components/Skeleton';
import { ToastLoadError } from '../../components/ToastLoadError';
import { Competences } from '../../components/Competences';

import { CompetenceTree } from '../../types/CompetenceTree';

import { UserContext } from '../../context';

import style from './Competence.module.css';

const cx = classNames.bind(style);

export const Competence: React.FC<RouteChildrenProps<{ id: string }>> = (
  props
) => {
  const intl = useIntl();
  const [competences, setCompetences] = useState<CompetenceTree | null>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const title = intl.formatMessage({ id: 'competence.title' });
  const user = useContext(UserContext);

  const handleFetch = useCallback(() => {
    setIsLoading(true);

    fetchEmployeeCompetences(user.id)
      .then((competences) => {
        setIsLoading(false);
        setCompetences(competences);
      })
      .catch((error) => {
        if (error.status === 'NOT_FOUND') {
          props.history.replace('/404');
          return;
        }
        setIsError(true);
      });
  }, [props.history, user.id]);

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  return (
    <>
      <DocumentTitle title={title} />

      {isError && <ToastLoadError onClick={handleFetch} />}
      <div className={cx('title')}>
        <Title type='h2' as='h1'>
          {title}
        </Title>
      </div>
      <div className={cx('content')}>
        {isLoading ? (
          <>
            <TabsSkeleton isLoading={isLoading} />
          </>
        ) : (
          <Competences competences={competences as CompetenceTree} />
        )}
      </div>
    </>
  );
};
