import React from 'react';
import classNames from 'classnames/bind';

import { Section } from '@sputnik/ui';
import { SkeletonItem } from '../Item';

import style from './Params.module.css';

const cx = classNames.bind(style);

type Props = {
  isLoading: boolean;
};

export const ParamsSkeleton: React.FC<Props> = ({ isLoading }) => (
  <Section level={2}>
    <div className={cx('wrapper')}>
      <div className={cx('line')}>
        <SkeletonItem isLoading={isLoading} />
      </div>
      <div className={cx('line')}>
        <SkeletonItem isLoading={isLoading} />
      </div>
      <div className={cx('line')}>
        <SkeletonItem isLoading={isLoading} />
      </div>
    </div>
  </Section>
);
