import '@sputnik/ui/style/global.css';
import '@sputnik/ui/style/fonts.css';
import '@sputnik/ui/style/variables.css';

import React, { useEffect, useState, useCallback } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { useCookies } from 'react-cookie';

import 'focus-visible';

import { fetchUser } from './api/login';

import { Container } from './components/Container';
import { Page } from './components/Page';

import { Login } from './pages/Login';
import { Home } from './pages/Home';
import { Competence } from './pages/Competence';
import { Schedule } from './pages/Schedule';
import { Course } from './pages/Course';
import { OnlineCourses } from './pages/OnlineCourses';
import { OnlineCourse } from './pages/OnlineCourse';
import { NotFound } from './pages/NotFound';
import { User } from 'types/User';

import {
  UserContext,
  UIContext,
  LanguageContext,
  Values,
  Language,
} from './context';

import ru from './locales/ru.json';
import kz from './locales/kz.json';

const renderPages = () => (
  <Container>
    <Page>
      <Route path='/profile' component={Home} />
      <Route path='/competence' component={Competence} />
      <Route
        path='/schedule'
        exact
        render={(props) => (
          <Schedule
            title='schedule.title'
            visibleTypes={['free_course', 'lms', 'assessment_360']}
            {...props}
          />
        )}
      />
      <Route path='/schedule/:assignmentId' component={Course} exact />
      <Route
        path='/assessments'
        exact
        render={(props) => (
          <Schedule
            title='assessments.title'
            visibleTypes={['assessment']}
            {...props}
          />
        )}
      />
      <Route
        path='/assessments/:assignmentId'
        exact
        render={(props) => <Course {...props} />}
      />
      <Route
        path='/competences-check'
        exact
        render={(props) => (
          <Schedule
            title='competences-check.title'
            visibleTypes={['competence_check']}
            {...props}
          />
        )}
      />
      <Route
        path='/competences-check/:assignmentId'
        exact
        render={(props) => <Course {...props} />}
      />
    </Page>
  </Container>
);

const renderOnline = () => (
  <Page>
    <Route path='/online' component={OnlineCourses} exact />
    <Route path='/online/:courseId' component={OnlineCourse} />
  </Page>
);

const messages = { ru, kz } as const;

const App: React.FC = () => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const [language, setLanguage] = useState<Language>(cookies.language || 'ru');
  const [user, setUser] = useState<User | null>();
  const [values, setValues] = useState<Values>({
    selfDiagnosis: false,
  });

  const handleFetch = useCallback(async () => {
    try {
      const data = await fetchUser();
      setUser(data);
    } catch (error) {
      setUser(null);
      removeCookie('token');

      if (window.location.pathname !== '/') {
        window.location.pathname = '/';
      }
    }
  }, [removeCookie]);

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  if (user === undefined) {
    return null;
  }

  return (
    <Router>
      <IntlProvider
        messages={messages[language]}
        locale={language}
        defaultLocale='ru'
      >
        <UIContext.Provider
          value={{
            values,
            setValue: (key, value) => {
              setValues({
                ...values,
                [key]: value,
              });
            },
          }}
        >
          <LanguageContext.Provider
            value={{
              value: language,
              onChange: () => {
                const newLang = language === 'ru' ? 'kz' : 'ru';
                setLanguage(newLang);
                setCookie('language', newLang, {
                  path: '/',
                  maxAge: 1000 * 24 * 60 * 60,
                });
              },
            }}
          >
            <Switch>
              <Route
                path='/'
                exact
                render={(props) =>
                  user ? (
                    <Redirect to='/profile' />
                  ) : (
                    <Login {...props} onLogin={handleFetch} />
                  )
                }
              />
              {user && (
                <UserContext.Provider value={user}>
                  <Route
                    path={[
                      '/profile',
                      '/competence',
                      '/schedule',
                      '/schedule/:assignmentId',
                      '/assessments',
                      '/assessments/:assignmentId',
                      '/competences-check',
                      '/competences-check/:assignmentId',
                    ]}
                    render={renderPages}
                  />
                  <Route
                    path={['/online', '/online/:courseId']}
                    render={renderOnline}
                  />

                  <Route
                    path={[
                      '/:id/profile',
                      '/:id/competence',
                      '/:id/schedule',
                      '/:id/schedule/:assignmentId',
                      '/:id/online',
                      '/:id/online/:courseId',
                    ]}
                    exact
                    render={() => <Redirect to='/profile' />}
                  />
                </UserContext.Provider>
              )}

              <Route path='*' component={NotFound} />
            </Switch>
          </LanguageContext.Provider>
        </UIContext.Provider>
      </IntlProvider>
    </Router>
  );
};

export default App;
