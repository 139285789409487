import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { RouteComponentProps } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames/bind';

import { LoginForm, Button } from '@sputnik/ui';

import { login, changePassword } from 'api/login';
import { LoginLayout } from 'components/LoginLayout';
import { PasswordNewForm } from 'components/PasswordNewForm';

import style from './Login.module.css';

const cx = classNames.bind(style);

export const Login: React.FC<
  RouteComponentProps & { onLogin: () => Promise<void> }
> = (props) => {
  const [state, setState] = useState<'LOGIN' | 'CHANGE'>('LOGIN');
  const [password, setPassword] = useState<string>('');

  const intl = useIntl();
  const titleMsg = intl.formatMessage({ id: 'pages.login.employee-login' });
  const passwordMsg = intl.formatMessage({
    id: 'pages.login.wrong-password---',
  });
  const unknownErrorMsg = intl.formatMessage({
    id: 'pages.recovery.unknown-error',
  });
  const invalidUser = intl.formatMessage({
    id: 'pages.login.invalid-password',
  });

  const handleFinish = useCallback(async () => {
    await props.onLogin();
    props.history.push('/profile');
  }, [props]);

  useEffect(() => {
    const win = window as any;

    if (win.onLogout) {
      win.onLogout();
    }
  }, []);

  return (
    <LoginLayout>
      {state === 'LOGIN' && (
        <LoginForm
          title={titleMsg}
          onSubmit={async (username, password) => {
            try {
              const data = await login(username, password);

              if (data.password_change_required) {
                setState('CHANGE');
                setPassword(password);
              } else {
                await handleFinish();
              }
            } catch (error) {
              let code = error?.code || error?.data?.code;
              let title = error?.title || error?.data?.title;

              if (code === 'invalid_user') {
                return Promise.reject({
                  password: invalidUser,
                });
              }

              return Promise.reject({
                password: title || unknownErrorMsg,
              });
            }
          }}
          validationSchema={yup.object().shape({
            login: yup.string().required(),
            password: yup.string().required(),
          })}
        >
        </LoginForm>
      )}
      {state === 'CHANGE' && (
        <PasswordNewForm
          onSubmit={async (new_password) => {
            try {
              await changePassword(password, new_password);
              await handleFinish();
            } catch (error) {
              return Promise.reject({
                password: error.errors.new_password,
              });
            }
          }}
        />
      )}
    </LoginLayout>
  );
};
