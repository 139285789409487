import React from 'react';

import classNames from 'classnames/bind';

import style from './ButtonIcon.module.css';

const cx = classNames.bind(style);

type ButtonProps = {
  as: 'button';
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

type AnchorProps = {
  as: 'a';
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

type Props = {
  size: 'xl';
  color?: 'white';
  icon: React.ComponentType<any>;
  activated?: boolean;
} & (ButtonProps | AnchorProps);

export const ButtonIcon: React.FC<Props> = ({
  size,
  as,
  icon: Icon,
  color,
  activated,
  ...rest
}) => {
  const Component = (as as unknown) as React.ComponentType<
    React.HTMLAttributes<HTMLElement>
  >;

  return (
    <Component
      className={cx('button', {
        button_size_xl: size === 'xl',
        button_color_white: color === 'white',
        button_activated: activated,
      })}
      {...rest}
    >
      <Icon width={24} height={24} />
    </Component>
  );
};
