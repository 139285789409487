import React from 'react';
import classNames from 'classnames/bind';
import { FormattedMessage, useIntl } from 'react-intl';

import { Title, Description, Text, Section } from '@sputnik/ui';

import style from './ProfileHeader.module.css';

const cx = classNames.bind(style);


type Param = {
  label: string;
  value: string;
};

type Props = {
  image?: string;
  fullName: string;
  position?: string;
  phone?: string;
  email?: string;
};

export const ProfileHeader: React.FC<Props> = ({
  image,
  fullName,
  position,
  phone,
  email,
}) => {
  const intl = useIntl();
  const photoMsg = intl.formatMessage({ id: 'components.profile-header.photo' });

  return (
    <Section level={2}>
      <div className={cx('content')}>
        {image && (
          <img
            className={cx('photo')}
            src={image}
            alt={photoMsg}
          />
        )}
        <div>
          <Title type='h3'>{fullName}</Title>
          <div className={cx('position')}>
            <Description size='s' color={600}>
              {position}
            </Description>
          </div>
          {(phone || email) && (
            <div className={cx('contacts')}>
              {phone && (
                <div className={cx('contact')}>
                  <Text color={900}>{phone}</Text>
                </div>
              )}
              {email && (
                <div className={cx('contact')}>
                  <Text color={900}>{email}</Text>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Section>
  );
};
