import React from 'react';
import classNames from 'classnames/bind';

import { Section } from '@sputnik/ui';
import { SkeletonItem } from '../Item';

import style from './Profile.module.css';

const cx = classNames.bind(style);

type Props = {
  isLoading: boolean;
};

export const ProfileSkeleton: React.FC<Props> = ({ isLoading }) => (
  <Section level={2}>
    <header className={cx('header')}>
      <div className={cx('photo')}>
        <SkeletonItem isLoading={isLoading} />
      </div>
      <div className={cx('title')}>
        <SkeletonItem isLoading={isLoading} />
      </div>
    </header>
    <div className={cx('line')}>
      <SkeletonItem isLoading={isLoading} />
    </div>
    <div className={cx('line')}>
      <SkeletonItem isLoading={isLoading} />
    </div>
    <footer className={cx('footer')}>
      <SkeletonItem isLoading={isLoading} />
    </footer>
  </Section>
);
