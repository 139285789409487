import React, { useState } from 'react';
import classNames from 'classnames/bind';
import useClickOutside from 'click-outside-hook';

import style from './Profile.module.css';

const cx = classNames.bind(style);

type Props = {
  name: string;
  position?: string;
  image?: string;
  menu?: {
    title: React.ReactNode;
    icon: React.ComponentType<any>;
    onClick: () => void;
  }[];
};

export const Profile: React.FC<Props> = (props) => {
  const [isActive, setIsActive] = useState(false);
  const handleOpen = () => {
    setIsActive(true);
  };
  const handleClose = () => {
    setIsActive(false);
  };

  const ref = useClickOutside(handleClose);

  return (
    <section
      className={cx('profile', {
        profile_withMenu: !!props.menu,
        profile_active: isActive,
      })}
      ref={ref}
      onClick={handleOpen}
    >
      <div>
        <p className={cx('name')}>{props.name}</p>
        {props.position && <p className={cx('position')}>{props.position}</p>}
      </div>
      {props.image ? (
        <img src={props.image} alt={props.name} className={cx('image')} />
      ) : (
        <p className={cx('image')}>{props.name[0]}</p>
      )}

      {props.menu && (
        <ul
          className={cx('menu', {
            menu_visible: isActive,
          })}
        >
          {props.menu.map((item) => (
            <li className={cx('menuItem')}>
              <button
                className={cx('menuButton')}
                onClick={item.onClick}
                type='button'
              >
                <item.icon width={24} height={24} className={cx('menuIcon')} />
                {item.title}
              </button>
            </li>
          ))}
        </ul>
      )}
    </section>
  );
};
