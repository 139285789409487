import React from 'react';
import classNames from 'classnames/bind';
import { FormattedMessage } from 'react-intl';

import { Button, Title, Modal, Text } from '@sputnik/ui';
import { ReactComponent as Clock } from '@sputnik/ui/icons/clock-alert-outline.svg';

import style from './Timeout.module.css';

const cx = classNames.bind(style);

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
};

export const Timeout: React.FC<Props> = ({ isOpen, onRequestClose }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className={cx('content')}>
        <Clock width={45} height={45} className={cx('icon')} />
        <Title type='h2'><FormattedMessage id={'competence-request.error.timeout'} /></Title>
        <div className={cx('info')}>
          <Text size='s' color={600}>
            <FormattedMessage id={'competence-request.error.try-later'} />
            <br />
            <FormattedMessage id={'competence-request.error.access-after'} /> <span className={cx('label')}>23 ч</span>.
          </Text>
        </div>

        <div className={cx('button')}>
          <Button as='button' size='xl' onClick={onRequestClose} block>
            <FormattedMessage id={'competence-request.error.close'} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
