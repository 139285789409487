import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames/bind';
import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';

import { declOfNum } from 'utils/formatDuration';

import { Title, Text, Description } from '@sputnik/ui';
import { ReactComponent as Calendar } from '@sputnik/ui/icons/calendar-blank.svg';
import { ReactComponent as Download } from '@sputnik/ui/icons/download.svg';

import style from './EventList.module.css';

const cx = classNames.bind(style);



const color = {
  COMPLETED: 'positive-500',
  PROGRESS: 'primary-500',
  NOT_COMPLETED: 'negative-500',
} as const;

type Props = {
  data: {
    id: number;
    result: number | null;
    title: string;
    status?: 'COMPLETED' | 'PROGRESS' | 'NOT_COMPLETED';
    start: Date;
    end?: Date;
    assessee: string | null;
    file: string | null;
    minutes: number | null;
    isStarted: boolean;
  }[];
};

const formatDate = (date: Date) =>
  format(date, 'dd MMMM', {
    locale: ru,
  });

export const EventList: React.FC<Props> = ({ data }) => {
  const handleDownload = (title: string, file: string) => {
    saveAs(file, `${title}.pdf`);
  };
  const intl = useIntl();
  const completedMsg = intl.formatMessage({ id: 'components.event-list.completed' });
  const inProcessMsg = intl.formatMessage({ id: 'components.event-list.in-process' });
  const notCompletedMsg = intl.formatMessage({ id: 'components.event-list.not-completed' });
  const timeOverMsg = intl.formatMessage({ id: 'schedule.course.time-is-over' });
  const timeLeftMsg = intl.formatMessage({ id: 'schedule.course.time-left' });
  const min1Msg = intl.formatMessage({ id: 'schedule.course.minute1' });
  const min2Msg = intl.formatMessage({ id: 'schedule.course.minute2' });
  const min3Msg = intl.formatMessage({ id: 'schedule.course.minute3' });
  const allottedTimeMsg = intl.formatMessage({ id: 'schedule.course.allotted-time' });
  const label = {
    COMPLETED: { completedMsg },
    PROGRESS: { inProcessMsg },
    NOT_COMPLETED: { notCompletedMsg },
  };

  return (
    <ul className={cx('list')}>
      {data.map(
        ({
          id,
          title,
          result,
          status,
          start,
          end,
          assessee,
          file,
          minutes,
          isStarted,
        }) => {
          const isDisabledByDate =
            start.valueOf() > Date.now() || (end && end.valueOf() < Date.now());
          const isDisabledByFile = !!file;
          const isDisabledByTime = minutes === 0;
          const isDisabled = isDisabledByFile || isDisabledByTime;
          const Component = isDisabled ? 'div' : Link;

          return (
            <li className={cx('item')}>
              {file && (
                <button
                  type='button'
                  onClick={() => handleDownload(title, file)}
                  className={cx('download')}
                >
                  <Download width={16} height={16} />
                </button>
              )}
              <Component to={`./${id}`} className={cx('link')}>
                <div>
                  <Title type='h4' as='p'>
                    {title}
                  </Title>
                  {minutes !== null && !isDisabledByFile && (
                    <div className={cx('label')}>
                      <Text>
                        {minutes === 0
                          ? {timeOverMsg}
                          : isStarted
                            ? `${timeLeftMsg} ${minutes} ${declOfNum(minutes, [
                              min1Msg,
                              min2Msg,
                              min3Msg,
                            ])}`
                            : `${allottedTimeMsg} ${minutes} ${declOfNum(minutes, [
                              min1Msg,
                              min2Msg,
                              min3Msg,
                            ])}`}
                      </Text>
                    </div>
                  )}
                  {assessee && (
                    <div className={cx('label')}>
                      <Text>{assessee}</Text>
                    </div>
                  )}
                  {result && (
                    <div className={cx('label')}>
                      <Description>
                        <FormattedMessage id={'components.event-list.result'} /> {Math.round(result * 100)}%
                      </Description>
                    </div>
                  )}
                </div>
                <div className={cx('date')}>
                  <Calendar className={cx('icon')} width={20} height={20} />
                  <Description color={900}>
                    {formatDate(start)}
                    {end ? ` — ${formatDate(end)}` : ''}
                  </Description>
                </div>
              </Component>
            </li>
          );
        }
      )}
    </ul>
  );
};
