import React from 'react';
import classNames from 'classnames/bind';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext } from 'formik';

import { Title, Input, Button } from '@sputnik/ui';

import { FormValues } from '../types';

import style from '../PasswordChange.module.css';

const cx = classNames.bind(style);


type Props = {
  onSubmit: () => void;
};

export const FormCurrent: React.FC<Props> = ({ onSubmit }) => {
  const { values, submitCount, errors, setFieldValue } = useFormikContext<
    FormValues
  >();
  const intl = useIntl();
  const oldPasswordMsg = intl.formatMessage({ id: 'components.password-change.old-password' });
  
  return (
    <>
      <Title type='h3' as='h1'>
        <FormattedMessage id={'components.header.header.change-password'} />
      </Title>
      <div className={cx('field')}>
        <Input
          label={oldPasswordMsg}
          placeholder={oldPasswordMsg}
          value={values.password}
          onChange={(value) => setFieldValue('password', value)}
          name='password'
          type='password'
          autoComplete='current-password'
          error={submitCount > 0 && errors.password}
        />
      </div>

      <div className={cx('submit')}>
        <Button
          as='button'
          type='button'
          size='xl'
          block
          disabled={!values.password}
          onClick={onSubmit}
        >
          <FormattedMessage id={'components.password-change.next'} />
        </Button>
      </div>
    </>
  );
};
