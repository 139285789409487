import React from 'react';
import classNames from 'classnames/bind';
import { FormattedMessage } from 'react-intl';

import { Button, Title, Modal, Text } from '@sputnik/ui';
import { ReactComponent as Alert } from '@sputnik/ui/icons/alert-circle-outline.svg';

import style from './Error.module.css';

const cx = classNames.bind(style);

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  code: number;
  description?: string;
};

export const Error: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  code,
  description,
}) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className={cx('content')}>
        <Alert width={45} height={45} className={cx('icon')} />
        <Title type='h2'><FormattedMessage id={'competence-request.error.error-occurred'} /> ({code})</Title>
        {description && (
          <div className={cx('info')}>
            <Text size='s' color={600}>
              {description}
            </Text>
          </div>
        )}

        <div className={cx('buttons')}>
          <div className={cx('button')}>
            <Button
              as='button'
              size='xl'
              onClick={onRequestClose}
              block
              color='white'
            >
              <FormattedMessage id={'competence-request.error.close'} />
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
