import { Employee } from '../types/Employee';
import { EmployeeCompetence } from '../types/EmployeeCompetence';
import { EmployeeSkill } from '../types/EmployeeSkill';
import { Skill } from '../types/Skill';
import { CompetenceTree } from '../types/CompetenceTree';
import { CourseAssignment } from '../types/CourseAssignment';
import { Lesson } from '../types/Lesson';

import { ItemStatus } from 'components/LessonList';

import { transformCompetences } from './utils/transformCompetences';
import { get } from './utils/get';
import { LessonAttendance } from 'types/LessonAttendance';
import { CompetenceGroup } from 'types/CompetenceGroup';
import { Competence } from 'types/Competence';
import { Course } from 'types/Course';

type LTIRequest = {
  form_data: Record<string, string>;
  url: string;
};

export async function fetch(id: number): Promise<Employee> {
  return get<Employee>(`/v1/employees/${id}`).then((user) => {
    if (user) {
      return user;
    }

    return Promise.reject({
      status: 'NOT_FOUND',
    });
  });
}

export async function fetchCompetenceGroups(): Promise<CompetenceGroup[]> {
  const group = get<CompetenceGroup[]>(`/v1/competence-groups`);

  return group;
}

export async function fetchCompetences(
  groups: CompetenceGroup[]
): Promise<Competence[]> {
  const competences = await Promise.all(
    groups.map((item) =>
      get<Competence[]>(`/v1/competence-groups/${item.id}/competences`)
    )
  );

  return competences.flat();
}

export async function fetchCourses(competences: number[], isSelfTest = true) {
  const data = await Promise.all(
    competences.map((item) =>
      get<Course[]>(
        `/v1/competences/${item}/courses?self_testing_only=${isSelfTest}`
      )
    )
  );

  return data
    .map((courses, index) =>
      courses.map((item) => ({ ...item, courseId: competences[index] }))
    )
    .flat();
}

export async function fetchEmployeeCompetences(
  userId: number
): Promise<CompetenceTree> {
  const [employeeCompetences, employeeSkills] = await Promise.all([
    get<EmployeeCompetence[]>(`/v1/employees/${userId}/competences`),
    get<EmployeeSkill[]>(`/v1/employees/${userId}/skills`),
  ]);

  const competencesSkills = await Promise.all(
    employeeCompetences.map(({ competence }) =>
      get<Skill[]>(`/v1/competences/${competence.id}/skills`)
    )
  );

  return transformCompetences(
    employeeCompetences,
    employeeSkills,
    competencesSkills
  );
}

export async function fetchAssignments() {
  const data = await get<CourseAssignment[]>('/v1/assignments');

  return data;
}

function getLessonsUrl(assignment: CourseAssignment) {
  let lessons_url = `/v1/assessments/${assignment.id}`;

  if (assignment.study_type !== 'assessment') {
    lessons_url = `/v1/courses/${assignment.course.id}/lessons`;
  }

  return lessons_url;
}

export async function fetchAssignmentsFull(
  list: CourseAssignment['study_type'][]
) {
  const data = await fetchAssignments();

  const dataFull = await Promise.all(
    data
      .filter((item) => list.includes(item.study_type))
      .map(async (item) => {
        const lessons = await get<Lesson[]>(getLessonsUrl(item));

        const attendances = await get<LessonAttendance[]>(
          `/v1/assignments/${item.id}/attendances`
        );
        const exam = lessons.find((item) => item.is_exam);
        const examAttendance = attendances.find(
          (a) => a.lesson_id === exam?.id
        );

        return {
          ...item,
          result: examAttendance?.result || null,
        };
      })
  );

  return dataFull;
}

export function getAssignmentName(assignment: CourseAssignment) {
  let name = assignment.name;
  if (assignment.course) {
    name = assignment.course.name;
  }

  return name;
}

export async function fetchLessons(assignmentId: string) {
  const assignments = await fetchAssignments();
  const assignment = assignments.find((item) => `${item.id}` === assignmentId);

  if (!assignment) {
    throw new Error('');
  }

  const lessons = await get<Lesson[]>(getLessonsUrl(assignment));

  const attendaces = await get<LessonAttendance[]>(
    `/v1/assignments/${assignment.id}/attendances`
  );

  return {
    name: getAssignmentName(assignment),
    minutes: assignment.assessment_remaining_time_minutes,
    isStarted: !!assignment.assessment_start_time,
    lessons: lessons.map((item) => {
      const attendace = attendaces.find((a) => a.lesson_id === item.id);
      let status: ItemStatus = 'NEW' as const;

      if (attendace) {
        status = attendace.is_completed
          ? ('COMPLETED' as const)
          : ('NOT_COMPLETED' as const);
      }

      return {
        id: item.id,
        title: item.name,
        status,
        language: item.language,
        type: item.is_exam ? ('TEST' as const) : ('LESSON' as const),
      };
    }),
  };
}

function submitForm(url: string, data: Record<string, string>) {
  const form = document.createElement('form');
  form.setAttribute('method', 'post');
  form.setAttribute('action', url);

  Object.keys(data).forEach((key) => {
    const value = data[key];
    const i = document.createElement('input');

    i.setAttribute('name', key);
    i.setAttribute('value', value);
    form.appendChild(i);
  });

  document.body.appendChild(form);
  form.submit();
}

export async function startLesson(assignmentId: string, lessonId: number) {
  const data = await get<LTIRequest>(
    `/v1/assignments/${assignmentId}/start-lesson`,
    {
      method: 'POST',
      body: JSON.stringify({
        lesson_id: lessonId,
        return_url: window.location.href,
      }),
    }
  );

  submitForm(data.url, data.form_data);
}

export async function startSelfTesting(courseId: number) {
  const data = await get<LTIRequest>(`/v1/courses/${courseId}/self-testing`, {
    method: 'POST',
    body: JSON.stringify({
      return_url: window.location.href,
    }),
  });

  submitForm(data.url, data.form_data);
}
