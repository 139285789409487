import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import DocumentTitle from 'react-document-title';
import { RouteChildrenProps } from 'react-router-dom';
import uniq from 'ramda/src/uniq';
import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';
import { useIntl } from 'react-intl';

import { Title, Select } from '@sputnik/ui';

import { EventList } from '../../components/EventList';
import { Filters } from '../../components/Filters';

import { fetchAssignmentsFull, getAssignmentName } from '../../api/employee';

import style from './Schedule.module.css';
import { CourseAssignment } from 'types/CourseAssignment';

const cx = classNames.bind(style);

export const Schedule: React.FC<
  RouteChildrenProps<{ id: string }> & {
    visibleTypes: CourseAssignment['study_type'][];
    title: string;
  }
> = (props) => {
  const intl = useIntl();
  const [year, setYear] = useState<string>('all');
  const [month, setMonth] = useState<string>('all');
  const [courses, setCourses] = useState<
    (CourseAssignment & { result: number | null })[]
  >([]);
  const handleFetch = async () => {
    const data = await fetchAssignmentsFull(props.visibleTypes);

    setCourses(data);
  };
  const title = intl.formatMessage({
    id: props.title,
  });

  const years = uniq(
    courses.map((course) => {
      const date = new Date(course.start_date);
      const year = date.getFullYear();

      return {
        label: `${year}`,
        value: `${year}`,
      };
    })
  );

  const months = uniq(
    courses.map((course) => {
      const date = new Date(course.start_date);
      const month = format(date, 'LLLL', {
        locale: ru,
      });

      return {
        label: `${month}`,
        value: `${date.getMonth()}`,
      };
    })
  );

  useEffect(() => {
    handleFetch();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const data = courses.filter((item) => {
    const date = new Date(item.start_date);
    const itemYear = date.getFullYear();
    const itemMonth = date.getMonth();

    const isMatchYear = year === 'all' || year === `${itemYear}`;
    const isMatchMonth = month === 'all' || month === `${itemMonth}`;

    return isMatchYear && isMatchMonth;
  });

  console.log(data);

  const tabs = [
    {
      id: '0',
      label: intl.formatMessage({ id: 'schedule.filters.type.all' }),
      count: data.length,
    },
    // {
    //   id: '1',
    //   label: intl.formatMessage({ id: 'schedule.filters.type.not_completed' }),
    //   count: 2,
    // },
    // {
    //   id: '2',
    //   label: intl.formatMessage({ id: 'schedule.filters.type.completed' }),
    //   count: 2,
    // },
  ];

  return (
    <>
      <DocumentTitle title={title} />

      <div className={cx('content')}>
        <div className={cx('title')}>
          <Title type='h2' as='h1'>
            {title}
          </Title>
        </div>
        <div className={cx('filters')}>
          <Filters tabs={tabs}>
            {years.length > 0 && (
              <Select
                iconPosition='right'
                options={[
                  {
                    label: intl.formatMessage({
                      id: 'schedule.filters.years.all',
                    }),
                    value: 'all',
                  },
                  ...years,
                ]}
                value={year}
                onChange={setYear}
              />
            )}
            <Select
              iconPosition='right'
              options={[
                {
                  value: 'all',
                  label: intl.formatMessage({
                    id: 'schedule.filters.months.all',
                  }),
                },
                ...months,
              ]}
              value={month}
              onChange={setMonth}
            />
          </Filters>
        </div>
        <EventList
          data={data.map((item) => ({
            id: item.id,
            title: getAssignmentName(item),
            result: item.result,
            start: new Date(item.start_date),
            end: item.end_date ? new Date(item.end_date) : undefined,
            file: item.assessment_result_doc_url,
            minutes: item.assessment_remaining_time_minutes,
            isStarted: !!item.assessment_start_time,
            assessee: item.assessee
              ? [
                  item.assessee.last_name,
                  item.assessee.middle_name,
                  item.assessee.first_name,
                ].join(' ')
              : null,
          }))}
        />
      </div>
    </>
  );
};
