import React from 'react';
import classNames from 'classnames/bind';

import { CompetenceGroup } from '../CompetenceGroup';
import { CompetenceInfo } from '../CompetenceInfo';
import { SkillTable } from '../SkillTable';

import { CompetenceTree } from '../../types/CompetenceTree';

import style from './Competences.module.css';

const cx = classNames.bind(style);

type Props = {
  competences: CompetenceTree;
};

export const Competences: React.FC<Props> = ({ competences }) => {
  return (
    <>
      {competences.map((group) => (
        <div className={cx('group')}>
          <CompetenceGroup
            title={group.label}
            icon={group.icon}
            progress={group.progress}
            endDate={group.endDate}
          >
            {group.uom !== 'binary' ? (
              group.items.map((subgroup) => (
                <div className={cx('group')}>
                  <CompetenceGroup
                    endDate={subgroup.endDate}
                    title={subgroup.title}
                    progress={subgroup.progress}
                    isSubgroup
                  >
                    {!!subgroup.items.length && (
                      <SkillTable data={subgroup.items} />
                    )}
                  </CompetenceGroup>
                </div>
              ))
            ) : (
              <CompetenceInfo data={group.items} />
            )}
          </CompetenceGroup>
        </div>
      ))}
    </>
  );
};
