import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { Formik, Form as FormikForm } from 'formik';
import Fuse from 'fuse.js';
import { useIntl, FormattedMessage } from 'react-intl';

import { Button, Title, Modal, Text, Input } from '@sputnik/ui';

import { fetchCourses } from 'api/employee';
import { Course } from 'types/Course';

import { RadioLesson } from 'components/RadioLesson';

import style from './Form.module.css';

const cx = classNames.bind(style);

type Props = {
  competence: number;
  isOpen: boolean;
  onRequestClose: () => void;
  onSubmit: (courseId: number) => Promise<any>;
};

type FormValues = {
  search?: string;
  group?: number;
  competence?: number;
  course?: number;
};

export const Form: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  onSubmit,
  competence,
}) => {
  const intl = useIntl();
  const [data, setData] = useState<{
    courses: (Course & { courseId: number })[];
  }>();

  const handleFetch = async () => {
    const courses = await fetchCourses([competence], false);

    setData({ courses });
  };

  useEffect(() => {
    if (isOpen && !data) {
      handleFetch();
    }
  }, [data, isOpen, competence, handleFetch]);

  if (!data) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} withClose>
      <Formik<FormValues>
        onSubmit={(values) => {
          return onSubmit((values.course as unknown) as number);
        }}
        validate={(values) => {
          if (!values.course) {
            return {
              course: 'ERROR',
            };
          }
        }}
        initialErrors={{
          course: 'ERROR',
        }}
        initialValues={{}}
      >
        {({ values, isValid, setFieldValue, isSubmitting }) => {
          const fuse = new Fuse(data.courses, {
            keys: ['name'],
          });
          const result = values.search
            ? fuse.search(values.search).map(({ item }) => item)
            : data.courses;

          return (
            <FormikForm className={cx('content')}>
              <Title type='h2'><FormattedMessage id={'components.competence-request.send-request'} /></Title>
              <div className={cx('info')}>
                <Text size='s' color={600}>
                  <FormattedMessage id={'components.competence-request.you-can-send-request'} />
                </Text>
              </div>
              <div className={cx('line')}>
                <Input
                  placeholder={intl.formatMessage({
                    id: 'selfdiagnosis.filters.name.placeholder',
                  })}
                  value={values.search}
                  onChange={(value) => setFieldValue('search', value)}
                  clearable
                />
              </div>

              <div className={cx('line')}>
                <RadioLesson<number>
                  name='course'
                  options={result.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  value={values.course}
                  onChange={(value) => setFieldValue('course', value)}
                />
              </div>

              <div className={cx('button')}>
                <Button
                  as='button'
                  size='xl'
                  block
                  disabled={!isValid}
                  isLoading={isSubmitting}
                  type='submit'
                >
                  <FormattedMessage id={'components.competence-request.apply'} />
                </Button>
              </div>
            </FormikForm>
          );
        }}
      </Formik>
    </Modal>
  );
};
