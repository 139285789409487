import React, { useState, useContext } from 'react';
import classNames from 'classnames/bind';
import { FormattedMessage } from 'react-intl';

import { Button } from '@sputnik/ui';
import { ReactComponent as Cached } from '@sputnik/ui/icons/cached.svg';

import { startSelfTesting } from 'api/employee';

import { Form } from './Form';
import { Error } from './Error';
import { Timeout } from './Timeout';

import { UIContext } from '../../context';

import style from './SelfDiagnosis.module.css';

const cx = classNames.bind(style);

type State = 'FORM' | 'ERROR' | 'TIMEOUT';

export const SelfDiagnosis: React.FC = () => {
  const { values, setValue } = useContext(UIContext);
  const isOpen = values.selfDiagnosis;
  const setIsOpen = (value: boolean) => setValue('selfDiagnosis', value);
  const [state, setState] = useState<State>('FORM');
  const [error, setError] = useState<{ code: number; description?: string }>({
    code: 500,
  });

  const handleClose = () => {
    setIsOpen(false);

    setTimeout(() => {
      setState('FORM');
    }, 300);
  };

  const handleSubmit = async (courseId: number) => {
    try {
      await startSelfTesting(courseId);
    } catch (error) {
      setError({
        code: error.status,
        description: error.data.title,
      });
      setState('ERROR');
    }
  };

  return (
    <div className={cx('container')}>
      <Button
        as='button'
        size='l'
        startIcon={Cached}
        color='white'
        onClick={() => setIsOpen(true)}
      >
        <FormattedMessage id='menu.selfdiagnosis' />
      </Button>
      {state === 'FORM' && (
        <Form
          isOpen={isOpen}
          onRequestClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}

      {state === 'TIMEOUT' && (
        <Timeout isOpen={isOpen} onRequestClose={handleClose} />
      )}

      {state === 'ERROR' && (
        <Error
          isOpen={isOpen}
          onRequestClose={handleClose}
          code={error.code}
          description={error.description}
        />
      )}
    </div>
  );
};
