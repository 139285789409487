import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import DocumentTitle from 'react-document-title';
import { RouteChildrenProps } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, Title, Text } from '@sputnik/ui';
import { ReactComponent as ArrowLeft } from '@sputnik/ui/icons/arrow-left.svg';

import { LessonList, Item, ItemStatus } from '../../components/LessonList';
import { Filters } from '../../components/Filters';

import { fetchLessons, startLesson } from '../../api/employee';

import { declOfNum } from '../../utils/formatDuration';

import style from './Course.module.css';

const cx = classNames.bind(style);

type Tabs = 'ALL' | ItemStatus;

export const Course: React.FC<RouteChildrenProps<{
  id: string;
  assignmentId: string;
}>> = (props) => {
  const intl = useIntl();
  const [tab, setTab] = useState<Tabs>('ALL');
  const [data, setData] = useState<{
    name: string;
    minutes: number | null;
    isStarted: boolean;
    lessons: (Item & { language: null | string })[];
  }>({
    name: '',
    minutes: null,
    isStarted: false,
    lessons: [],
  });
  const handleFetch = async () => {
    const data = await fetchLessons(props.match?.params.assignmentId as string);

    setData(data);
  };

  useEffect(() => {
    handleFetch();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const tabs = [
    {
      id: 'ALL',
      label: intl.formatMessage({ id: 'schedule.course.filters.type.all' }),
      count: data.lessons.length,
    },
    {
      id: 'NEW',
      label: intl.formatMessage({ id: 'schedule.course.filters.type.new' }),
      count: data.lessons.filter((item) => item.status === 'NEW').length,
    },
    {
      id: 'NOT_COMPLETED',
      label: intl.formatMessage({
        id: 'schedule.course.filters.type.not_completed',
      }),
      count: data.lessons.filter((item) => item.status === 'NOT_COMPLETED')
        .length,
    },
    {
      id: 'COMPLETED',
      label: intl.formatMessage({
        id: 'schedule.course.filters.type.completed',
      }),
      count: data.lessons.filter((item) => item.status === 'COMPLETED').length,
    },
  ];

  const title = data.name;

  return (
    <>
      <DocumentTitle title={title} />

      <div className={cx('content')}>
        <div className={cx('link')}>
          <Button size='m' color='text' startIcon={ArrowLeft} as='a' href='./'>
            <FormattedMessage id={'schedule.course.back'} />
          </Button>
        </div>
        <div className={cx('title')}>
          <Title type='h2' as='h1'>
            {title}
          </Title>
          {data.minutes !== null && (
            <Text>
              {data.minutes === 0
                ? <FormattedMessage id={'schedule.course.time-is-over'} />  //'Время вышло'
                : data.isStarted
                  ? `${intl.formatMessage({ id: 'schedule.course.time-left' })} ${data.minutes} ${declOfNum(data.minutes, [
                      intl.formatMessage({ id: 'schedule.course.minute1' }),
                      intl.formatMessage({ id: 'schedule.course.minute2' }),
                      intl.formatMessage({ id: 'schedule.course.minute3' }),
                  ])}`
                  : `${intl.formatMessage({ id: 'schedule.course.allotted-time' })} ${data.minutes} ${declOfNum(data.minutes, [
                    intl.formatMessage({ id: 'schedule.course.minute1' }),
                    intl.formatMessage({ id: 'schedule.course.minute2' }),
                    intl.formatMessage({ id: 'schedule.course.minute3' }),
                  ])}`}
            </Text>
          )}
        </div>
        <div className={cx('filters')}>
          <Filters tabs={tabs} onTabChange={(value) => setTab(value as Tabs)} />
        </div>
        <LessonList
          data={data.lessons
            .filter((item) => item.status === tab || tab === 'ALL')
            .filter((item) => !item.language || item.language === intl.locale)}
          onClick={(id) => {
            startLesson(props.match?.params.assignmentId as string, id);
          }}
        />
      </div>
    </>
  );
};
