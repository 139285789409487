import React from 'react';
import classNames from 'classnames/bind';

import { Description } from '@sputnik/ui';

import style from './ProgressBar.module.css';

const cx = classNames.bind(style);

type Props = {
  label: React.ReactNode;
  value: number;
  max?: number;
  theme?: 'SKILL' | 'KNOWLEDGE';
};

const toRange = (count: number) =>
  Array.from({ length: count }, (x, i) => i + 1);

export const ProgressBar: React.FC<Props> = ({ label, value, max, theme }) => {
  return (
    <div
      className={cx('progressBar', {
        progressBar_knowledge: theme === 'KNOWLEDGE',
      })}
    >
      <div className={cx('value')}>
        {max ? (
          <p className={cx('text')}>
            {value}
            <span className={cx('max')}>/{max}</span>
          </p>
        ) : (
          <p className={cx('text')}>{Math.round(value)}%</p>
        )}
        <Description size='s' color={500}>
          {label}
        </Description>
      </div>
      <div className={cx('bricks')}>
        {max ? (
          toRange(max).map((item) => (
            <div
              className={cx('brick')}
              style={
                { '--fill': value >= item ? 100 : 0 } as React.CSSProperties
              }
            />
          ))
        ) : (
          <div
            className={cx('brick')}
            style={{ '--fill': value } as React.CSSProperties}
          />
        )}
      </div>
    </div>
  );
};
