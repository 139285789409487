import React from 'react';

import classNames from 'classnames/bind';

import style from './Title.module.css';

const cx = classNames.bind(style);

type Props = {
  type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  as?: string;
};

export const Title: React.FC<Props> = ({ type, as, children }) => {
  const Component = ((as || type) as unknown) as React.ComponentType<
    React.HTMLAttributes<HTMLElement>
  >;

  return (
    <Component
      className={cx('title', {
        title_type_h1: type === 'h1',
        title_type_h3: type === 'h3',
        title_type_h2: type === 'h2',
        title_type_h4: type === 'h4',
      })}
    >
      {children}
    </Component>
  );
};
