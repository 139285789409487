import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import { Sidebar as SidebarUI } from '@sputnik/ui';

import { ReactComponent as Profile } from '@sputnik/ui/icons/badge-account-outline.svg';
import { ReactComponent as Competence } from '@sputnik/ui/icons/head-snowflake-outline.svg';
import { ReactComponent as Schedule } from '@sputnik/ui/icons/calendar-multiple-check.svg';
import { ReactComponent as ProgressCheck } from '@sputnik/ui/icons/progress-check.svg';
import { ReactComponent as Book } from '@sputnik/ui/icons/book-open-outline.svg';

import { UIContext } from '../../context';

export const Sidebar: React.FC = () => {
  const intl = useIntl();
  const { setValue } = useContext(UIContext);
  const items = [
    {
      icon: Profile,
      label: intl.formatMessage({ id: 'menu.profile' }),
      href: `/profile/`,
    },
    {
      icon: Competence,
      label: intl.formatMessage({ id: 'menu.competence' }),
      href: `/competence/`,
    },
    {
      icon: Schedule,
      label: intl.formatMessage({ id: 'menu.schedule' }),
      href: `/schedule/`,
    },
    {
      icon: Schedule,
      label: intl.formatMessage({ id: 'menu.assessments' }),
      href: `/assessments/`,
    },
    {
      icon: Schedule,
      label: intl.formatMessage({ id: 'menu.competences-check' }),
      href: `/competences-check/`,
    },
  ];
  const menuItems = [
    {
      icon: ProgressCheck,
      label: intl.formatMessage({ id: 'menu.selfdiagnosis' }),
      onClick: () => setValue('selfDiagnosis', true),
    },
    {
      icon: Book,
      label: intl.formatMessage({ id: 'menu.online-courses' }),  //'Онлайн-курсы',
      href: `/online/`,
    },
  ];
  return <SidebarUI items={items} menuItems={menuItems} />;
};
