import React from 'react';
import classNames from 'classnames/bind';

import style from './Item.module.css';

const cx = classNames.bind(style);

type Props = {
  isLoading: boolean;
  theme?: 300;
};

export const SkeletonItem: React.FC<Props> = ({ isLoading, theme }) => {
  return (
    <div
      className={cx('skeletonItem', {
        skeletonItem_loading: isLoading,
        skeletonItem_theme_300: theme === 300,
      })}
    />
  );
};
