import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import DocumentTitle from 'react-document-title';
import { RouteChildrenProps, useRouteMatch } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Title, Text, Description, Section, Button } from '@sputnik/ui';
import { ReactComponent as Book } from '@sputnik/ui/icons/book-open-page-variant-outline.svg';
import { ReactComponent as Calendar } from '@sputnik/ui/icons/calendar-blank.svg';
import { ReactComponent as CalendarMultiple } from '@sputnik/ui/icons/calendar-multiple-check.svg';
import { ReactComponent as BookOpen } from '@sputnik/ui/icons/book-open-outline.svg';
import { ReactComponent as FileDocument } from '@sputnik/ui/icons/file-document-box-check-outline.svg';

import { getCourse, enrollCourse } from '../../api/courses';

import { Course } from 'types/Course';
import { Lesson } from 'types/Lesson';

import style from './OnlineCourse.module.css';
import { OnlineCourseSuccess } from 'components/OnlineCourseSuccess';

const cx = classNames.bind(style);

export const OnlineCourse: React.FC<RouteChildrenProps<{ id: string }>> = (
  props
) => {
  const [course, setCourse] = useState<Course & { lessons: Lesson[] }>();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const match = useRouteMatch<{ id: string; courseId: string }>();
  const handleFetch = async () => {
    const data = await getCourse(match.params.courseId);
    setCourse(data);
  };

  const handleAdd = async () => {
    await enrollCourse(match.params.courseId);
    setIsSuccess(true);
  };

  useEffect(() => {
    handleFetch();
  }, []);

  if (!course) {
    return null;
  }

  const title = course.name;

  return (
    <>
      <DocumentTitle title={title} />
      <OnlineCourseSuccess
        isOpen={isSuccess}
        onRequestClose={() => setIsSuccess(false)}
      />
      <div className={cx('content')}>
        <Section level={1}>
          <div className={cx('course')}>
            <Title type='h1' as='h1'>
              {title}
            </Title>
            {course.description && (
              <div className={cx('description')}>
                <Text color={900}>{course.description}</Text>
              </div>
            )}
            {course.skill && (
              <div className={cx('skill')}>{course.skill.name}</div>
            )}
            <div className={cx('params')}>
              <Button
                startIcon={CalendarMultiple}
                as='button'
                size='l'
                onClick={handleAdd}
              >
                <FormattedMessage id={'pages.onlinecourse.add-cource-to-schedule'} />
              </Button>
              <span className={cx('courseInfo')}>
                <span className={cx('courseParam')}>
                  <Book width={20} height={20} />
                  <Description as='span' color={900}>
                    {course.lessons.length} <FormattedMessage id={'pages.onlinecourses.lessons'} />
                  </Description>
                </span>
                <span className={cx('courseParam')}>
                  <Calendar width={20} height={20} />
                  <Description as='span' color={900}>
                    {course.duration_days || 0} <FormattedMessage id={'pages.onlinecourses.days'} />
                  </Description>
                </span>
              </span>
            </div>
          </div>
        </Section>
        <ul className={cx('lessons')}>
          {course.lessons.map((item) => (
            <Section as='li' level={0} key={item.id}>
              <div className={cx('lesson')}>
                {item.is_exam ? (
                  <div className={cx('icon', 'icon_exam')}>
                    <FileDocument width={24} height={24} />
                  </div>
                ) : (
                  <div className={cx('icon')}>
                    <BookOpen width={24} height={24} />
                  </div>
                )}
                <Title type='h4' as='p'>
                  {item.name}
                </Title>
              </div>
            </Section>
          ))}
        </ul>
      </div>
    </>
  );
};
