import React from 'react';
import classNames from 'classnames/bind';
import { FormattedMessage } from 'react-intl';

import { Description, Text } from '@sputnik/ui';
import { ReactComponent as Check } from '@sputnik/ui/icons/check.svg';

import style from './RadioLesson.module.css';

const cx = classNames.bind(style);

type Option<T = string> = {
  label: string;
  value: T;
};

type Props<T> = {
  name: string;
  options: Option<T>[];
  value: T | undefined;
  onChange: (value: T) => void;
};

export function RadioLesson<T>({ options, onChange, name, value }: Props<T>) {
  return (
    <div className={cx('list')}>
      <Description size='s' color={500}>
        <FormattedMessage id='selfdiagnosis.list' />
      </Description>

      <div className={cx('items')}>
        {options.map((item) => (
          <label className={cx('item')}>
            <input
              type='radio'
              className={cx('radio')}
              name={name}
              value={`${item.value}`}
              checked={item.value === value}
              onChange={() => onChange(item.value)}
            />
            <Text size='s' color={900} as='span'>
              {item.label}
            </Text>
            <div className={cx('check')}>
              <Check width={14} height={14} />
            </div>
          </label>
        ))}
      </div>
    </div>
  );
}
