import React from 'react';
import classNames from 'classnames/bind';

import { ReactComponent as Close } from '@sputnik/ui/icons/close.svg';

import style from './Input.module.css';

const cx = classNames.bind(style);

type Props = Omit<React.HTMLProps<HTMLInputElement>, 'onChange'> & {
  icon?: React.ComponentType<any>;
  onChange?: (value: string) => void;
  clearable?: boolean;
  label?: React.ReactNode;
  error?: React.ReactNode;
};

export const Input: React.FC<Props> = ({
  icon: Icon,
  value,
  onChange,
  clearable,
  label,
  error,
  ...rest
}) => {
  return (
    <div
      className={cx('container', {
        container_labeled: !!label,
        container_withIcon: !!Icon,
        container_filled: !!value,
        container_error: !!error,
      })}
    >
      <label className={cx('wrapper')}>
        {Icon && <Icon width={20} height={20} className={cx('icon')} />}
        <input
          type='text'
          className={cx('input')}
          value={value}
          onChange={(event) => onChange && onChange(event.target.value)}
          {...rest}
        />
        {label && <span className={cx('label')}>{label}</span>}
      </label>
      {error && <span className={cx('error')}>{error}</span>}
      {clearable && value && (
        <button
          className={cx('clear')}
          type='button'
          onClick={() => onChange && onChange('')}
        >
          <Close width={20} height={20} />
        </button>
      )}
    </div>
  );
};
