import React from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames/bind';

import { Title, Description, Tooltip } from '@sputnik/ui';
import { ReactComponent as Book } from '@sputnik/ui/icons/book-open-outline.svg';
import { ReactComponent as Document } from '@sputnik/ui/icons/file-document-box-check-outline.svg';
import { ReactComponent as Trophy } from '@sputnik/ui/icons/trophy-outline.svg';

import style from './LessonList.module.css';

const cx = classNames.bind(style);


const color = {
  COMPLETED: 'positive-500',
  NEW: 600,
  NOT_COMPLETED: 'negative-500',
} as const;

const icons = {
  LESSON: Book,
  TEST: Document,
  QUIZ: Trophy,
} as const;

export type ItemStatus = 'NEW' | 'COMPLETED' | 'NOT_COMPLETED';
export type ItemType = 'LESSON' | 'TEST' | 'QUIZ';
export type Item = {
  id: number;
  title: string;
  status: ItemStatus;
  type: ItemType;
};

type Props = {
  data: Item[];
  onClick: (item: number) => void;
};

export const LessonList: React.FC<Props> = ({ data, onClick }) => {
  const intl = useIntl();
  const completedMsg = intl.formatMessage({ id: 'components.event-list.completed' });
  const rejectedMsg = intl.formatMessage({ id: 'components.lesson-list.rejected' });
  const notCompletedMsg = intl.formatMessage({ id: 'components.event-list.not-completed' });
  const lessonMsg = intl.formatMessage({ id: 'components.lesson-list.lesson' });
  const testMsg = intl.formatMessage({ id: 'components.lesson-list.test' });
  const quizMsg = intl.formatMessage({ id: 'components.lesson-list.quiz' });

  const label = {
    COMPLETED: completedMsg,
    NEW: notCompletedMsg,
    NOT_COMPLETED: rejectedMsg,
  };
  const tooltips = {
    LESSON: lessonMsg,
    TEST: testMsg,
    QUIZ: quizMsg,
  } as const;
  
  return (
    <ul className={cx('list')}>
      {data.map(({ id, title, status, type }) => {
        const Icon = icons[type];
        const hasUnvisitedNonTests = data
          .filter((lesson) => lesson.type !== 'TEST')
          .some((item) => item.status === 'NEW');
        const isDisabled =
          type === 'TEST'
            ? status === 'COMPLETED' || hasUnvisitedNonTests
            : false;

        return (
          <li className={cx('item')} key={id}>
            <button
              type='button'
              className={cx('link')}
              disabled={isDisabled}
              onClick={() => onClick(id)}
            >
              <Tooltip content={tooltips[type]}>
                <div
                  className={cx('iconWrapper', {
                    iconWrapper_lesson: type === 'LESSON',
                    iconWrapper_test: type === 'TEST',
                    iconWrapper_quiz: type === 'QUIZ',
                  })}
                >
                  <Icon width={24} height={24} />
                </div>
              </Tooltip>
              <div className={cx('info')}>
                <Title type='h4' as='p'>
                  {title}
                </Title>
                {type !== 'TEST' && (
                  <div className={cx('label')}>
                    <Description color={color[status]}>
                      {label[status]}
                    </Description>
                  </div>
                )}
              </div>
            </button>
          </li>
        );
      })}
    </ul>
  );
};
