import { createContext } from 'react';

import { User } from 'types/User';

export type Language = 'ru' | 'kz';

export type Values = {
  selfDiagnosis: boolean;
};

type UI = {
  values: Values;
  setValue: (key: keyof Values, value: boolean) => void;
};

export const UserContext = createContext<User>({} as User);
export const UIContext = createContext<UI>({} as UI);
export const LanguageContext = createContext<{
  value: Language;
  onChange: () => void;
}>({
  value: 'ru',
  onChange: () => null,
});
