import { Tabs } from './Tabs';

export const getNextHorizontalActiveTab = (
  event: React.KeyboardEvent,
  tabs: Tabs,
  current: string
): string | void => {
  const currentIndex = tabs.findIndex((item) => item.id === current);

  if (event.key === 'Home') {
    return tabs[0].id;
  }

  if (event.key === 'End') {
    return tabs[tabs.length - 1].id;
  }

  if (event.key === 'ArrowLeft') {
    return tabs[Math.max(0, currentIndex - 1)].id;
  }

  if (event.key === 'ArrowRight') {
    return tabs[Math.min(tabs.length - 1, currentIndex + 1)].id;
  }

  return undefined;
};
