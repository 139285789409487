import { EmployeeCompetence } from '../../../types/EmployeeCompetence';
import { EmployeeSkill } from '../../../types/EmployeeSkill';
import { Skill } from '../../../types/Skill';
import { CompetenceTree } from '../../../types/CompetenceTree';

import { ReactComponent as Additional } from '@sputnik/ui/icons/plus.svg';
import { ReactComponent as Professional } from '@sputnik/ui/icons/account-hard-hat.svg';

const maxByType = {
  binary: 1,
  level: 3,
  percent: undefined,
};

export function transformCompetences(
  competences: EmployeeCompetence[],
  skills: EmployeeSkill[],
  competencesSkills: Skill[][]
): CompetenceTree {
  const rootGroups = competences.reduce(
    (
      acc: { [key: string]: CompetenceTree[0] },
      {
        competence,
        expiration_date,
        knowledge_current_level,
        skill_current_level,
        competence_category,
      },
      index
    ) => {
      let { id, name, group } = competence;
      const max = maxByType[group.uom];

      if (competence_category === 'self_testing') {
        group = {
          id: 0,
          name: 'Дополнительные',
          uom: group.uom,
        };
      }

      if (!acc[group.id]) {
        acc[group.id] = {
          icon:
            competence_category === 'self_testing' ? Additional : Professional,
          label: group.name,
          id: group.id,
          uom: group.uom,
          progress: {
            // skill: {
            //   value: 0.8,
            // },
            // knowledge: {
            //   value: 0.7,
            // },
          },
          items: [],
          order:
            competence_category === 'self_testing'
              ? 999
              : Object.keys(acc).length,
        };
      }

      const endDate = expiration_date ? new Date(expiration_date) : undefined;

      if (endDate && endDate.valueOf() - Date.now() < 30 * 86400 * 1000) {
        acc[group.id].endDate = endDate;
      }

      acc[group.id].items.push({
        id: id,
        title: name,
        endDate,
        progress: {
          skill: {
            value: skill_current_level,
            max,
          },
          knowledge: {
            value: knowledge_current_level,
            max,
          },
        },
        items: competencesSkills[index].map((skill) => {
          const userSkill = skills.find((s) => s.skill.id === skill.id);

          return {
            id: skill.id,
            title: skill.name,
            type: skill.skill_type,
            level: userSkill?.current_level || 0,
            required: userSkill?.reference_level || 0,
            max,
          };
        }),
      });

      return acc;
    },
    {}
  );

  return Object.values(rootGroups).sort((a, b) => a.order - b.order);
}
