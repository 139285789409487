import React from 'react';

import classNames from 'classnames/bind';

import style from './Text.module.css';

const cx = classNames.bind(style);

type Props = {
  size?: 'xs' | 's' | 'm' | 'l';
  as?: string;
  color?: 900 | 600 | 500;
};

export const Text: React.FC<Props> = ({
  size = 'm',
  as = 'p',
  children,
  color,
  ...rest
}) => {
  const Component = (as as unknown) as React.ComponentType<
    React.HTMLAttributes<HTMLElement>
  >;

  return (
    <Component
      className={cx('text', {
        text_size_xs: size === 'xs',
        text_size_s: size === 's',
        text_size_m: size === 'm',
        text_size_l: size === 'l',
        text_color_900: color === 900,
        text_color_600: color === 600,
        text_color_500: color === 500,
      })}
      {...rest}
    >
      {children}
    </Component>
  );
};
