import React from 'react';
import classNames from 'classnames/bind';

import style from './Container.module.css';

const cx = classNames.bind(style);

type Props = {
  sidebar: React.ComponentType<any>;
};

export const Container: React.FC<Props> = ({ sidebar: Sidebar, children }) => {
  return (
    <div className={cx('container')}>
      <div className={cx('sidebar')}>
        <Sidebar />
      </div>
      <div className={cx('content')}>{children}</div>
    </div>
  );
};
