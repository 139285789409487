import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { Formik, Form as FormikForm } from 'formik';
import Fuse from 'fuse.js';
import { useIntl, FormattedMessage } from 'react-intl';

import { Button, Dropdown, Title, Modal, Text, Input } from '@sputnik/ui';

import {
  fetchCompetenceGroups,
  fetchCompetences,
  fetchCourses,
} from 'api/employee';
import { CompetenceGroup } from 'types/CompetenceGroup';
import { Competence } from 'types/Competence';
import { Course } from 'types/Course';

import { RadioLesson } from 'components/RadioLesson';

import style from './Form.module.css';

const cx = classNames.bind(style);

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  onSubmit: (courseId: number) => Promise<any>;
};

type FormValues = {
  search?: string;
  group?: number;
  competence?: number;
  course?: number;
};

const getCompetences = (competences: Competence[], groupId: number) => {
  return competences.filter((item) => item.group.id === groupId);
};

const getCourses = (
  courses: (Course & { courseId: number })[],
  competences: Competence[],
  competenceId?: number
) => {
  const competencesId = competences.map((item) => item.id);

  return courses.filter((item) => {
    if (competenceId) {
      return competenceId === item.courseId;
    } else {
      return competencesId.includes(item.courseId);
    }
  });
};

export const Form: React.FC<Props> = ({ isOpen, onRequestClose, onSubmit }) => {
  const intl = useIntl();
  const [data, setData] = useState<{
    groups: CompetenceGroup[];
    competences: Competence[];
    courses: (Course & { courseId: number })[];
  }>();

  const handleFetch = async () => {
    const groups = await fetchCompetenceGroups();
    const competences = await fetchCompetences(groups);
    const courses = await fetchCourses(competences.map((item) => item.id));

    setData({ groups, competences, courses });
  };

  useEffect(() => {
    if (isOpen && !data) {
      handleFetch();
    }
  }, [data, isOpen]);

  if (!data) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} withClose>
      <Formik<FormValues>
        onSubmit={(values) => {
          return onSubmit((values.course as unknown) as number);
        }}
        validate={(values) => {
          if (!values.course) {
            return {
              course: 'ERROR',
            };
          }
        }}
        initialErrors={{
          course: 'ERROR',
        }}
        initialValues={{}}
      >
        {({ values, isValid, setFieldValue, isSubmitting }) => {
          const competences = values.group
            ? getCompetences(data.competences, values.group)
            : data.competences;

          const courses = getCourses(
            data.courses,
            competences,
            values.competence
          );

          const fuse = new Fuse(courses, {
            keys: ['name'],
          });
          const result = values.search
            ? fuse.search(values.search).map(({ item }) => item)
            : courses;

          return (
            <FormikForm className={cx('content')}>
              <Title type='h2'>
                <FormattedMessage id='selfdiagnosis.title' />
              </Title>
              <div className={cx('info')}>
                <Text size='s' color={600}>
                  <FormattedMessage id='selfdiagnosis.description' />
                </Text>
              </div>
              <div className={cx('line')}>
                <Input
                  placeholder={intl.formatMessage({
                    id: 'selfdiagnosis.filters.name.placeholder',
                  })}
                  value={values.search}
                  onChange={(value) => setFieldValue('search', value)}
                  clearable
                />
              </div>
              <div className={cx('line')}>
                <div className={cx('field')}>
                  <Dropdown<number>
                    placeholder={intl.formatMessage({
                      id: 'selfdiagnosis.filters.group.placeholder',
                    })}
                    options={data.groups.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                    value={values.group}
                    onChange={(value) => {
                      setFieldValue('group', value);

                      const selectedCompetence = data.competences.find(
                        (item) => item.id === values.competence
                      );

                      if (
                        !selectedCompetence ||
                        selectedCompetence.group.id !== value
                      ) {
                        setFieldValue('competence', null);
                      }
                    }}
                  />
                </div>
                <div className={cx('field')}>
                  <Dropdown<number>
                    placeholder={intl.formatMessage({
                      id: 'selfdiagnosis.filters.competence.placeholder',
                    })}
                    options={competences.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                    value={values.competence}
                    onChange={(value) => setFieldValue('competence', value)}
                  />
                </div>
              </div>

              <div className={cx('line')}>
                <RadioLesson<number>
                  name='course'
                  options={result.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  value={values.course}
                  onChange={(value) => setFieldValue('course', value)}
                />
              </div>

              <div className={cx('button')}>
                <Button
                  as='button'
                  size='xl'
                  block
                  disabled={!isValid}
                  isLoading={isSubmitting}
                  type='submit'
                >
                  <FormattedMessage id='selfdiagnosis.submit' />
                </Button>
              </div>
            </FormikForm>
          );
        }}
      </Formik>
    </Modal>
  );
};
