import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames/bind';

import { Button, Title, Modal } from '@sputnik/ui';
import { ReactComponent as Calendar } from '@sputnik/ui/icons/calendar-multiple-check.svg';

import style from './OnlineCourseSuccess.module.css';

const cx = classNames.bind(style);

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
};

export const OnlineCourseSuccess: React.FC<Props> = ({
  isOpen,
  onRequestClose,
}) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} withClose>
      <div className={cx('content')}>
        <div className={cx('icon')}>
          <Calendar width={45} height={45} />
        </div>
        <Title type='h2'><FormattedMessage id={'components.online-cources-cuccess.cource-added'} /></Title>

        <div className={cx('button')}>
          <Button as='Link' size='xl' to='/profile/' block>
            <FormattedMessage id={'pages.notfound.to-personal-account'} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
