import { Notification } from 'types/Notification';

import { get } from './utils/get';

export async function getNotificationsCount() {
  const data = await get<{ unread: number }>('/v1/notifications/count');

  return data.unread;
}

export async function getNotifications() {
  const data = await get<Notification[]>('/v1/notifications');

  return data.sort((a, b) => b.id - a.id);
}

export async function setNotificationRead(id: number) {
  await get(`/v1/notifications/${id}/read`, {
    method: 'POST',
  });
}
