import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useIntl } from 'react-intl';

import { Title, ButtonIcon, Section, ProgressBar } from '@sputnik/ui';
import { ReactComponent as MenuDown } from '@sputnik/ui/icons/menu-down.svg';
import { ReactComponent as MenuUp } from '@sputnik/ui/icons/menu-up.svg';

import { Duration } from '../Duration';

import { Progress } from 'types/CompetenceTree';

import style from './CompetenceGroup.module.css';

const cx = classNames.bind(style);

type Props = {
  title: React.ReactNode;
  icon?: React.ComponentType<React.SVGAttributes<SVGElement>>;
  isSubgroup?: boolean;
  endDate?: Date;
  progress: {
    knowledge?: Progress;
    skill?: Progress;
  };
};

export const CompetenceGroup: React.FC<Props> = ({
  title,
  icon: Icon,
  isSubgroup,
  children,
  endDate,
  progress,
}) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Section level={isSubgroup ? undefined : 2}>
      <header
        className={cx('header', { header_subgroup: isSubgroup })}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={cx('icon')}>
          {Icon && <Icon width={24} height={24} />}
        </div>
        <Title type={isSubgroup ? 'h5' : 'h4'} as={isSubgroup ? 'h3' : 'h2'}>
          {title}
        </Title>
        <div className={cx('right')}>
          {endDate && (
            <div className={cx('item', 'item_duration')}>
              <Duration
                label={intl.formatMessage({ id: 'competence.duration' })}
                date={endDate}
              />
            </div>
          )}
          {progress.knowledge !== undefined && (
            <div className={cx('item')}>
              <ProgressBar
                {...progress.knowledge}
                label={intl.formatMessage({
                  id: 'competence.knowledge.plural',
                })}
              />
            </div>
          )}
          {progress.skill !== undefined && (
            <div className={cx('item')}>
              <ProgressBar
                {...progress.skill}
                label={intl.formatMessage({
                  id: 'competence.skill.plural',
                })}
              />
            </div>
          )}
        </div>
        <div className={cx('button')}>
          <ButtonIcon
            activated={isOpen}
            icon={isOpen ? MenuUp : MenuDown}
            size='xl'
            as='button'
          />
        </div>
      </header>
      <div
        className={cx('content', {
          content_visible: isOpen,
          content_subgroup: isSubgroup,
        })}
      >
        <div className={cx('inner')}>{children}</div>
      </div>
    </Section>
  );
};
