import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames/bind';

import { Button } from '@sputnik/ui';

import { Form } from './Form';
import { Error } from './Error';
import { Timeout } from './Timeout';

import style from './CompetenceRequest.module.css';

const cx = classNames.bind(style);

type State = 'FORM' | 'ERROR' | 'TIMEOUT';

type Props = {
  competence: number;
};

export const CompetenceRequest: React.FC<Props> = ({ competence }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [state, setState] = useState<State>('FORM');
  const [error, setError] = useState<{ code: number; description?: string }>({
    code: 500,
  });

  const handleClose = () => {
    setIsOpen(false);

    setTimeout(() => {
      setState('FORM');
    }, 300);
  };

  const handleSubmit = async (courseId: number) => {
    try {
      handleClose();
    } catch (error) {
      setError({
        code: error.status,
        description: error.data.title,
      });
      setState('ERROR');
    }
  };

  return (
    <div className={cx('container')}>
      <Button
        as='button'
        color='white'
        onClick={() => setIsOpen(true)}
        size='m'
      >
        <FormattedMessage id={'components.competence-request.send-request'} />
      </Button>
      {state === 'FORM' && (
        <Form
          competence={competence}
          isOpen={isOpen}
          onRequestClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}

      {state === 'TIMEOUT' && (
        <Timeout isOpen={isOpen} onRequestClose={handleClose} />
      )}

      {state === 'ERROR' && (
        <Error
          isOpen={isOpen}
          onRequestClose={handleClose}
          code={error.code}
          description={error.description}
        />
      )}
    </div>
  );
};
