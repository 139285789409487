import React from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';

import logo from './assets/logo.svg';
import style from './LoginLayout.module.css';

const cx = classNames.bind(style);

export const LoginLayout: React.FC<{}> = ({ children }) => {
  return (
    <div className={cx('page')}>
      <Link to='/'>
        <img
          src={logo}
          width='154'
          height='32'
          alt='Қазақстан темір жолы'
          className={cx('logo')}
        />
      </Link>
      {children}
    </div>
  );
};
