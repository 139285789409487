export function declOfNum(number: number, titles: string[]) {
  const cases = [2, 0, 1, 1, 1, 2];

  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
}

export function formatDuration(days: number, locale: string): string {
  const years = Math.floor(days / 365);
  const months = Math.floor((days - years * 365) / 30);

  const yearsLabel = declOfNum(years, [
    locale === 'ru' ? 'год' : 'жыл',
    locale === 'ru' ? 'года' : 'жыл',
    locale === 'ru' ? 'лет' : 'жаста',
  ]);
  const monthsLabel = declOfNum(years, [
    locale === 'ru' ? 'месяц' : 'ай',
    locale === 'ru' ? 'месяца' : 'ай',
    locale === 'ru' ? 'месяцев' : 'айлар',
  ]);

  return `${years} ${yearsLabel} ${months} ${monthsLabel}`;
}
