import React from 'react';

import classNames from 'classnames/bind';

import style from './Section.module.css';

const cx = classNames.bind(style);

type Props = {
  level?: 0 | 1 | 2 | 3;
  as?: string;
};

export const Section: React.FC<Props> = ({
  children,
  level,
  as = 'section',
}) => {
  const Component = (as as unknown) as React.ComponentType<
    React.HTMLAttributes<HTMLElement>
  >;
  return (
    <Component
      className={cx('section', {
        section_level_0: level === 0,
        section_level_1: level === 1,
        section_level_2: level === 2,
        section_level_3: level === 3,
      })}
    >
      {children}
    </Component>
  );
};
