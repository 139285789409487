import React, { Children, useState, useEffect } from 'react';
import classNames from 'classnames/bind';

import { getNextHorizontalActiveTab } from './utils';

import style from './Tabs.module.css';

const cx = classNames.bind(style);

export type Tabs = {
  id: string;
  label: React.ReactNode;
  count?: number;
  icon?: React.ComponentType<React.SVGAttributes<SVGElement>>;
}[];

type Props = {
  tabs: Tabs;
  section?: boolean;
  onChange?: (value: string) => void;
  size?: 's';
};

export const Tabs: React.FC<Props> = ({
  tabs,
  children,
  size,
  section,
  onChange,
}) => {
  const [selected, setSelected] = useState(tabs[0].id);

  useEffect(() => {
    if (onChange) {
      onChange(selected);
    }
  }, [onChange, selected]);

  const handleChange = (value: string) => {
    setSelected(value);

    if (onChange) {
      onChange(value);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent): void => {
    const nextTab = getNextHorizontalActiveTab(event, tabs, selected);

    if (nextTab) {
      const tab = document.getElementById(nextTab);

      tab?.click();
      tab?.focus();
    }
  };

  const tab = Children.toArray(children).find(
    (child: any) => child.props.id === selected
  ) as React.ReactElement<any>;

  return (
    <div>
      <div
        role='tablist'
        className={cx('tabs', {
          tabs_size_s: size === 's',
          tabs_section: section,
        })}
      >
        {tabs.map(({ id, label, count, icon: Icon }) => (
          <button
            key={id}
            id={id}
            type='button'
            className={cx('tab')}
            role='tab'
            aria-selected={selected === id}
            aria-controls={`${id}-tab`}
            tabIndex={selected === id ? 0 : -1}
            onClick={(): void => handleChange(id)}
            onKeyDown={handleKeyPress}
          >
            {Icon && <Icon width={24} height={24} className={cx('icon')} />}
            {label}
            {count !== undefined && (
              <span className={cx('count')}>{count}</span>
            )}
          </button>
        ))}
      </div>
      {tab &&
        React.cloneElement(tab, {
          role: 'tabpanel',
          tabIndex: 0,
          'aria-labelledby': tab.props.id,
          id: `${tab.props.id}-tab`,
        })}
    </div>
  );
};
