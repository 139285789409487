import React from 'react';
import classNames from 'classnames/bind';
import { FormattedMessage, useIntl } from 'react-intl';

import { Title, Description, Text, Section } from '@sputnik/ui';

import { Param } from '../Param';

import style from './ProfileInfo.module.css';

const cx = classNames.bind(style);

type Param = {
  label: string;
  value: string;
};

type Props = {
  header: Param[];
  organization: Param[];
  career: {
    params: Param[][];
    chief?: {
      photo: string;
      fullName: string;
    };
  };
  education: Param[][];
};

const Group: React.FC<{
  title?: string;
  params: Param[][];
  vertical?: boolean;
}> = ({ title, params, vertical, children }) => {
  const intl = useIntl();

  return (
    <div className={cx('group')}>
      {title && (
        <div className={cx('title')}>
          <Title type='h4' as='h3'>
            {title}
          </Title>
        </div>
      )}
      {params.map((group) => (
        <ul
          className={cx('list', {
            list_vertical: vertical,
          })}
        >
          {group.map(({ label, value }) => (
            <Param
              key={label}
              label={intl.formatMessage({ id: label })}
              value={value}
            />
          ))}
        </ul>
      ))}
      {children}
    </div>
  );
};

export const ProfileInfo: React.FC<Props> = ({
  header,
  organization,
  career,
  education,
}) => {
  const intl = useIntl();

  return (
    <Section level={2}>
      <Group params={[header]} />
      <Group
        title={intl.formatMessage({ id: 'profile.info.organization' })}
        params={[organization]}
        vertical
      />
      <Group
        title={intl.formatMessage({ id: 'profile.info.career' })}
        params={career.params}
      >
        {career.chief && (
          <div className={cx('chief')}>
            <img
              alt={intl.formatMessage({ id: 'components.profile-info.boss-photo' })}
              src={career.chief.photo}
              className={cx('chiefPhoto')}
            />
            <div className={cx('chiefInfo')}>
              <Description color={600}><FormattedMessage id={'components.profile-info.boss'} /></Description>
              <Text color={900}>{career.chief.fullName}</Text>
            </div>
          </div>
        )}
      </Group>
      {!!education.length && (
        <Group
          title={intl.formatMessage({ id: 'profile.info.career.education' })}
          params={education}
        />
      )}
    </Section>
  );
};
